import { useCallback, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import { SearchInput } from 'reducers/search';

const SEARCH_DEBOUNCE_IN_MS = 1000;
const FUZZY_SEARCH_CHANNELS = gql`
  query FuzzySearchChannels($searchInput: SearchInput!) {
    search(searchInput: $searchInput) {
      id
      channelName
      categoryHierarchy
      shortDescription
      isActive
      category {
        path
      }
    }
  }
`;
export const STATIC_PARAM_MAP: Record<string, string> = {
  channelName: 'Channel Name',
  componentNameOrIdentifier: 'Component',
  categoryNameOrIdentifier: 'Subcomponent',
  measurementTypeNameOrIdentifier: 'Measurement Type',
  locationNameOrIdentifier: 'Location',
  unitNameOrIdentifier: 'Unit',
  sampleRate: 'Sample Rate',
  description: 'Description',
  fixedChannelNames: 'Fixed Channel Names',
  legacyChannelNames: 'Legacy Channel Names',
};
export const STATIC_PARAM_MAP_INVERTED = _.invert(STATIC_PARAM_MAP);

const hasSearchInput = (searchInput: SearchInput) => {
  return searchInput.channelName
    || searchInput.componentNameOrIdentifier
    || searchInput.categoryNameOrIdentifier
    || searchInput.measurementTypeNameOrIdentifier
    || searchInput.locationNameOrIdentifier
    || searchInput.unitNameOrIdentifier
    || searchInput.sampleRate
    || searchInput.description
    || searchInput.legacyChannelNames.length !== 0
    || searchInput.fixedChannelNames.length !== 0;
};

type SearchValue = number | string | string[] | string[][];

export default () => {
  const initialState = {
    channelName: '',
    componentNameOrIdentifier: '',
    categoryNameOrIdentifier: '',
    measurementTypeNameOrIdentifier: '',
    locationNameOrIdentifier: '',
    unitNameOrIdentifier: '',
    sampleRate: '',
    description: '',
    legacyChannelNames: [],
    fixedChannelNames: [],
    limit: 10,
  };
  const [searchInput, setSearchInput] = useState(initialState);
  const [searchResults, setSearchResults] = useState([]);
  const [fuzzySearchChannels, { loading, error }] = useLazyQuery(FUZZY_SEARCH_CHANNELS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSearchResults(data?.search ?? []);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(_.debounce(input => {
    fuzzySearchChannels({
      variables: {
        searchInput: {
          channelName: input.channelName || null,
          componentNameOrIdentifier: input.componentNameOrIdentifier || null,
          categoryNameOrIdentifier: input.categoryNameOrIdentifier || null,
          measurementTypeNameOrIdentifier: input.measurementTypeNameOrIdentifier || null,
          locationNameOrIdentifier: input.locationNameOrIdentifier || null,
          unitNameOrIdentifier: input.unitNameOrIdentifier || null,
          sampleRate: input.sampleRate || null,
          description: input.description || null,
          legacyChannelNames: input.legacyChannelNames || [],
          fixedChannelNames: input.fixedChannelNames || [],
          limit: input.limit,
        },
      },
    });
  }, SEARCH_DEBOUNCE_IN_MS), []);

  const handleSearchInputChange = (field: string, value?: SearchValue) => {
    // Empties results on input change to avoid momentarily displaying
    // stale results
    setSearchResults([]);
    setSearchInput(input => {
      const updatedSearchInput = {
        ...input,
        [field]: value,
      };
      if (hasSearchInput(updatedSearchInput)) search(updatedSearchInput);
      return updatedSearchInput;
    });
  };

  const resetSearchInput = () => {
    setSearchResults([]);
    setSearchInput(initialState);
  };

  return {
    searchInput,
    handleSearchInputChange,
    resetSearchInput,
    hasSearchInput,
    searchLoading: loading,
    searchError: error,
    searchData: searchResults,
  };
};
