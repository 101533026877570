import React from 'react';
import * as Sentry from '@sentry/browser';

import ManualError from 'components/ManualError';

type PropsWithChildren<P = unknown> = P & { children?: React.ReactNode | undefined };

class ErrorBoundary extends React.Component<PropsWithChildren> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI
      return (
        <ManualError
          description={(
            <p>
              An error prevented this view from rendering.
              <br />
              If you continue to see this error, please notify the dev team.
            </p>
          )}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
