
import React, { MouseEventHandler } from 'react';
import { Button, FormGroup, FormGroupProps } from '@blueprintjs/core';
import { Select2 as BPSelect2 } from '@blueprintjs/select';
import { MenuItem2 } from '@blueprintjs/popover2';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';

import styles from './index.module.css';

interface FieldConfig {
  name: string;
  label?: string | React.ReactNode;
  validation?: any;
  disabled?: boolean;
  multiple?: boolean;
}

interface Option {
  label: string;
  value: string;
}

interface Props {
  control: any;
  config: FieldConfig;
  formGroupProps?: FormGroupProps;
  options: Option[];
  selectClassName?: string;
}

const OptionSelect = BPSelect2.ofType<Option>();

export const Select2 = (props: Props) => {
  return (
    <Controller
      name={props.config.name}
      control={props.control}
      render={({ field: { onChange, value } }) => {
        const onItemSelect = (item: Option) => {
          onChange({ target: { value: item.value } });
        };
        const itemPredicate = (query: string, item: Option) => item.label.includes(query);
        const itemRenderer = (item: Option, { handleClick }: { handleClick: MouseEventHandler<HTMLElement> }) => (
          <MenuItem2
            key={item.value}
            onClick={handleClick}
            text={item.label ?? item.value}
          />
        );

        return (
          <FormGroup
            label={props.config.label}
            labelFor={props.config.name}
            {...props.formGroupProps}
            labelInfo={props.config?.validation?.required ? '(required)' : ''}
          >
            <OptionSelect
              className="bp4-fill"
              disabled={props.config.disabled}
              itemPredicate={itemPredicate}
              itemRenderer={itemRenderer}
              items={props.options}
              onItemSelect={onItemSelect}
            >
              <Button
                className={classNames('bp4-fill', styles.select2Button)}
                disabled={props.config.disabled}
                rightIcon="double-caret-vertical"
                text={props.options.find(o => o.value === value)?.label}
              />
            </OptionSelect>
          </FormGroup>
        );
      }}
    />
  );
};

Select2.defaultProps = {
  formGroupProps: {},
  selectClassName: undefined,
};

export default Select2;
