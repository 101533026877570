import React, { useEffect } from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import {
  Intent,
} from '@blueprintjs/core';

import MeasurementTypeFormFields from 'components/MeasurementType/MeasurementTypeFormFields';
import FormButtons from 'components/FormButtons';
import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';
import { channelNameSegmentRegex } from 'config';

import styles from './index.module.css';

const GET_MEASUREMENT_TYPE = gql`
  query MeasurementTypeById($measurementTypeId: Int!) {
    measurementType: measurementTypeById(measurementTypeId: $measurementTypeId) {
      id
      identifier
      name
      description
    }
  }
`;

const UPDATE_MEASUREMENT_TYPE = gql`
  mutation MeasurementTypeUpdate($measurementTypeId: Int!, $measurementTypeInput: MeasurementTypeInput!) {
    measurementTypeUpdate(measurementTypeId: $measurementTypeId, measurementTypeInput: $measurementTypeInput) {
      id
    }
  }
`;

const defaultValues = {
  identifier: '',
  name: '',
  description: '',
};

const fieldsConfig = {
  identifier: {
    name: 'identifier',
    label: 'Identifier',
    validation: {
      required: true,
      pattern: channelNameSegmentRegex,
    },
    validationMessages: {
      pattern: 'Must only include letters and dashes. Cannot start or end with a dash.',
    },
  },
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
};

interface FormData {
  identifier: string;
  name: string;
  description: string;
}

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const measurementTypeId = Number(params.measurementTypeId);
  const form = useForm<FormData>({ defaultValues, mode: 'all' });

  const { loading, error, data } = useQuery(GET_MEASUREMENT_TYPE, { variables: { measurementTypeId } });

  const [updateMeasurementType, {
    loading: updateMeasurementTypeLoading,
  }] = useMutation(UPDATE_MEASUREMENT_TYPE, {
    refetchQueries: [
      { query: GET_MEASUREMENT_TYPE, variables: { measurementTypeId } },
    ],
    onCompleted: () => {
      toaster.show({ intent: Intent.SUCCESS, message: 'Updated successfully' });
      navigate(`/measurement-types/${measurementTypeId}`);
    },
    onError: ({ message }) => {
      toaster.show({
        intent: Intent.DANGER,
        message,
      });
    },
  });

  // Initialize form fields
  useEffect(() => {
    if (!data) return;

    form.setValue('identifier', data.measurementType.identifier);
    form.setValue('name', data.measurementType.name);
    form.setValue('description', data.measurementType.description || '');
  }, [data, form]);

  if (loading) return <Spinner />;
  if (error) throw error;

  const onSubmit = async (formData: any) => {
    const variables = {
      measurementTypeId: data.measurementType.id,
      measurementTypeInput: {
        identifier: formData.identifier,
        name: formData.name,
        description: formData.description || null,
      },
    };

    await updateMeasurementType({ variables });
  };

  return (
    <div className={styles.container}>
      <h1 className="bp4-heading">Edit {data.measurementType.name} Measurement Type</h1>

      <FormProvider {...form}>
        <form className={styles.form}>
          <MeasurementTypeFormFields
            fieldsConfig={fieldsConfig}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            disabled={updateMeasurementTypeLoading}
          />
        </form>
      </FormProvider>
    </div>
  );
};
