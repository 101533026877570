import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { State } from 'reducers';

export interface SearchInput {
  channelName: string;
  componentNameOrIdentifier: string;
  categoryNameOrIdentifier: string;
  measurementTypeNameOrIdentifier: string;
  locationNameOrIdentifier: string;
  unitNameOrIdentifier: string;
  sampleRate: string;
  description: string;
  legacyChannelNames: string[];
  fixedChannelNames: string[];
  limit: number;
}
export interface SearchState {
  input: SearchInput;
  loading: boolean;
  results: any[];
}

export const initialState: SearchState = {
  input: {
    channelName: '',
    componentNameOrIdentifier: '',
    categoryNameOrIdentifier: '',
    measurementTypeNameOrIdentifier: '',
    locationNameOrIdentifier: '',
    unitNameOrIdentifier: '',
    sampleRate: '',
    description: '',
    legacyChannelNames: [],
    fixedChannelNames: [],
    limit: 10,
  },
  loading: false,
  results: [],
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetInput: (state) => {
      state.input = { ...initialState.input };
    },
    setInput: (state, action: PayloadAction<SearchInput>) => {
      state.input = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.input.limit = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setResults: (state, action: PayloadAction<any[]>) => {
      state.results = action.payload;
    },
  },
});
export default searchSlice.reducer;

export const selectInput = (state: State) => state.search.input;
export const selectLoading = (state: State) => state.search.loading;
export const selectResults = (state: State) => state.search.results;

export const selectors = {
  selectResults,
};
