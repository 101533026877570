import { createSlice } from '@reduxjs/toolkit';

import { State } from 'reducers';

export interface Settings {
  darkMode: boolean;
  measurementTypeView: boolean;
}

export interface UIState {
  settings: Settings;
}

export const initialState = {
  settings: {
    darkMode: true,
    measurementTypeView: false,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleDarkMode: state => {
      state.settings.darkMode = !state.settings.darkMode;
    },
    toggleMeasurementTypeView: state => {
      state.settings.measurementTypeView = !state.settings.measurementTypeView;
    },
  },
});
export default uiSlice.reducer;

export const selectUserSettings = (state: State) => state.ui.settings;
export const selectDarkMode = (state: State) => state.ui.settings.darkMode;
export const selectMeasurementTypeView = (state: State) => state.ui.settings.measurementTypeView;

export const selectors = {
  selectDarkMode,
  selectMeasurementTypeView,
  selectUserSettings,
};
