import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import classNames from 'classnames';

import { LinkWithSearch } from 'components/LinkWithSearch';
import ManualError from 'components/ManualError';
import Spinner from 'components/Spinner';
import useCategoryDataByLocation from 'hooks/use-category-data-by-location';
import { sortByIdentifier } from 'helpers/general';
import { selectDarkMode, selectMeasurementTypeView, uiSlice } from 'reducers/ui';
import { Category } from 'types';
import { CATEGORY_ROOT_PATH_REGEXP } from '../../constants';
import Breadcrumb from './Breadcrumb';
import NavMenu from './NavMenu';

import styles from './index.module.css';

const GET_ALL_MEASUREMENT_TYPES = gql`
  query GetAllMeasurementTypes {
    measurementTypes {
      id
      identifier
      name
    }
  }
`;

export function CategoryNav() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const darkMode: boolean = useSelector(selectDarkMode);
  const measurementTypeView: boolean = useSelector(selectMeasurementTypeView);
  const {
    loading: categoryByLocationLoading,
    error: categoryByLocationError,
    categoryByLocation,
  } = useCategoryDataByLocation();
  const {
    loading: getAllMeasurementTypesLoading,
    error: getAllMeasurementTypesError,
    data: getAllMeasurementTypesData,
  } = useQuery(GET_ALL_MEASUREMENT_TYPES);

  const isRootCategory = !categoryByLocation.hasParentCategory;
  const measurementTypeId = searchParams.get('measurementTypeId');
  // No dependencies (run once!); If there's a measurementType ID in the URL,
  // OR if there's no measurementType ID and in measurementType view and this isn't
  // the root page, toggle measurementType view automatically on load
  useEffect(() => {
    if ((measurementTypeId && !measurementTypeView) || (!measurementTypeId && measurementTypeView && !isRootCategory)) {
      dispatch(uiSlice.actions.toggleMeasurementTypeView());
    }
  });

  const categoryId = categoryByLocation.id;
  const path = pathname.replace(CATEGORY_ROOT_PATH_REGEXP, '');

  const renderCategories = () => {
    const { categories } = categoryByLocation;

    return sortByIdentifier(categories).map((category: Category) => {
      const categoryIsActive = category.path === path;
      return (
        <LinkWithSearch key={category.id} to={`/components/${category.path}`}>
          <li className={classNames({ [styles.active]: categoryIsActive })} title={category.name}>
            {/* <span className={styles.ident}>{category.identifier}</span> */}
            <span>{category.name}</span>
          </li>
        </LinkWithSearch>
      );
    });
  };

  const renderMeasurementTypes = () => {
    const { measurementTypes } = getAllMeasurementTypesData;
    return [...measurementTypes]
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
      .map((measurementType: any) => {
        const measurementTypeIsActive = Number(measurementTypeId) === measurementType.id;
        return (
          <Link key={measurementType.id} to={`${pathname}?measurementTypeId=${measurementType.id}`}>
            <li className={classNames({ [styles.active]: measurementTypeIsActive })}>
              {measurementType.name}
            </li>
          </Link>
        );
      });
  };

  const loading = categoryByLocationLoading || getAllMeasurementTypesLoading;
  const error = categoryByLocationError ?? getAllMeasurementTypesError;

  return (
    <div className={styles.container}>
      <div className={styles.actionBarContainer}>
        {isRootCategory && !measurementTypeId
          ? <span>Choose a {measurementTypeView ? 'measurement type' : 'component'}</span>
          : <Breadcrumb />
        }
        <NavMenu
          isRootCategory={isRootCategory}
          categoryId={categoryId}
        />
      </div>
      <div className={classNames(styles.listContainer, {
        [styles.listContainerLight]: !darkMode,
        [styles.listContainerDark]: darkMode,
      })}
      >
        {loading && (
          <Spinner />
        )}
        {error && (
          <ManualError description={error.message} />
        )}
        {!loading && !error && (
          <ol className={classNames(styles.list, {
            [styles.listLight]: !darkMode,
            [styles.listDark]: darkMode,
          })}
          >
            {measurementTypeView && isRootCategory ? renderMeasurementTypes() : renderCategories()}
          </ol>
        )}
      </div>
    </div>
  );
}

export default CategoryNav;
