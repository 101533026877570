import React from 'react';
import {
  gql,
  useMutation,
} from '@apollo/client';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import {
  Intent,
} from '@blueprintjs/core';
import {
  useNavigate,
} from 'react-router-dom';

import toaster from 'helpers/toaster';
import FormButtons from 'components/FormButtons';
import UnitFormFields from 'components/Unit/UnitFormFields';

import styles from './index.module.css';

const CREATE_UNIT = gql`
  mutation UnitCreate($unitInput: UnitInput!) {
    unitCreate(unitInput: $unitInput) {
      id
    }
  }
`;

const defaultValues = {
  name: '',
  identifier: '',
  description: '',
};

const fieldsConfig = {
  identifier: {
    name: 'identifier',
    label: 'Identifier',
    validation: {
      required: true,
      pattern: /^(?!-).+(?<!-)$/i,
    },
    validationMessages: {
      pattern: 'Cannot start or end with a dash.',
    },
  },
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
};

interface FormData {
  name: string;
  identifier: string;
  description: string;
}

export default () => {
  const navigate = useNavigate();

  const form = useForm<FormData>({ defaultValues, mode: 'all' });

  const [createUnit, {
    loading: createUnitLoading,
  }] = useMutation(CREATE_UNIT, {
    onCompleted: () => {
      toaster.show({ intent: Intent.SUCCESS, message: 'Created successfully' });
      navigate('/units');
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message,
    }),
  });

  const onSubmit = async (formData: any) => {
    const variables = {
      unitInput: {
        identifier: formData.identifier,
        name: formData.name,
        description: formData.description || null,
      },
    };

    await createUnit({ variables });
  };

  return (
    <div className={styles.container}>
      <h1 className="bp4-heading">Add Unit</h1>

      <FormProvider {...form}>
        <form className={styles.form}>
          <UnitFormFields
            fieldsConfig={fieldsConfig}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            disabled={createUnitLoading}
          />
        </form>
      </FormProvider>
    </div>
  );
};
