import React from 'react';
import {
  gql,
  useQuery,
} from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import { sortByIdentifier } from 'helpers/general';
import useCheckPermission from 'hooks/use-check-permission';

import styles from './index.module.css';

const GET_MEASUREMENT_TYPES = gql`
  query MeasurementTypes {
    measurementTypes {
      id
      identifier
      name
      description
    }
  }
`;

const columns = [
  'Name',
  'Identifier',
  'Description',
];

const formatRow = (measurementType: any) => {
  return {
    link: `/measurement-types/${measurementType.id}`,
    values: [
      measurementType.name,
      measurementType.identifier,
      measurementType.description,
    ],
  };
};

export default () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_MEASUREMENT_TYPES, {
    fetchPolicy: 'cache-and-network',
  });

  const [canAddMeasurements] = useCheckPermission('create_measurement_types');

  const rowRenderer = (row: any, rowIndex: number) => {
    return (
      <tr
        key={`row-${rowIndex}`}
        onClick={() => navigate(row.link)}
      >
        {row.values.map((value: string, colIndex: number) => (
          <td
            className={styles[`cell${columns[colIndex]}`]}
            key={`row-${rowIndex}-col-${colIndex}`}
          >
            {value}
          </td>
        ))}
      </tr>
    );
  };

  const renderAddMeasurementTypeButton = () => {
    if (!canAddMeasurements) return null;

    return (
      <Button
        icon="plus"
        onClick={() => navigate('/measurement-types/create')}
      >
        Add Measurement Type
      </Button>
    );
  };


  if (loading) return <Spinner />;
  if (error) throw error;

  const rows = sortByIdentifier(data.measurementTypes).map(formatRow);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={classNames(styles.heading, 'bp4-heading')}>Measurement Types</h1>
        {renderAddMeasurementTypeButton()}
      </header>

      <div className={styles.tableWrapper}>
        <Table
          columns={columns}
          rows={rows}
          rowRenderer={rowRenderer}
          classNames={['bp4-interactive', 'bp4-html-table-striped', styles.table]}
          sticky={{ top: 0 }}
        />
      </div>
    </div>
  );
};
