import React, { useEffect } from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import {
  Intent,
} from '@blueprintjs/core';

import UnitFormFields from 'components/Unit/UnitFormFields';
import FormButtons from 'components/FormButtons';
import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';

import styles from './index.module.css';

const GET_UNIT = gql`
  query UnitById($unitId: Int!) {
    unit: unitById(unitId: $unitId) {
      id
      identifier
      name
      description
    }
  }
`;

const UPDATE_UNIT = gql`
  mutation UnitUpdate($unitId: Int!, $unitInput: UnitInput!) {
    unitUpdate(unitId: $unitId, unitInput: $unitInput) {
      id
    }
  }
`;

const defaultValues = {
  identifier: '',
  name: '',
  description: '',
};

const fieldsConfig = {
  identifier: {
    name: 'identifier',
    label: 'Identifier',
    validation: {
      required: true,
      pattern: /^(?!-).+(?<!-)$/i,
    },
    validationMessages: {
      pattern: 'Cannot start or end with a dash.',
    },
  },
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
};

interface FormData {
  identifier: string;
  name: string;
  description: string;
}

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const unitId = Number(params.unitId);
  const form = useForm<FormData>({ defaultValues, mode: 'all' });

  const { loading, error, data } = useQuery(GET_UNIT, { variables: { unitId } });

  const [updateUnit, {
    loading: updateUnitLoading,
  }] = useMutation(UPDATE_UNIT, {
    refetchQueries: [
      { query: GET_UNIT, variables: { unitId } },
    ],
    onCompleted: () => {
      toaster.show({ intent: Intent.SUCCESS, message: 'Updated successfully' });
      navigate(`/units/${unitId}`);
    },
    onError: ({ message }) => {
      toaster.show({
        intent: Intent.DANGER,
        message,
      });
    },
  });

  // Initialize form fields
  useEffect(() => {
    if (!data) return;

    form.setValue('identifier', data.unit.identifier);
    form.setValue('name', data.unit.name);
    form.setValue('description', data.unit.description || '');
  }, [data, form]);

  if (loading) return <Spinner />;
  if (error) throw error;

  const onSubmit = async (formData: any) => {
    const variables = {
      unitId: data.unit.id,
      unitInput: {
        identifier: formData.identifier,
        name: formData.name,
        description: formData.description || null,
      },
    };

    await updateUnit({ variables });
  };

  return (
    <div className={styles.container}>
      <h1 className="bp4-heading">Edit {data.unit.name} Unit</h1>

      <FormProvider {...form}>
        <form className={styles.form}>
          <UnitFormFields
            fieldsConfig={fieldsConfig}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            disabled={updateUnitLoading}
          />
        </form>
      </FormProvider>
    </div>
  );
};
