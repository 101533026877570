import React from 'react';
import { Navigate, Route, createRoutesFromElements } from 'react-router-dom';

import CategoryNav from 'components/CategoryNav';
import Layout from 'components/Layout';

import Audit from './audit';

import MeasurementTypes from './measurement-types';
import MeasurementTypeDetail from './measurement-type-detail';
import MeasurementTypeCreate from './measurement-type-create';
import MeasurementTypeEdit from './measurement-type-edit';

import Locations from './locations';
import LocationDetail from './location-detail';
import LocationCreate from './location-create';
import LocationEdit from './location-edit';

import Units from './units';
import UnitDetail from './unit-detail';
import UnitCreate from './unit-create';
import UnitEdit from './unit-edit';

import SampleRates from './sample-rates';
import SampleRateDetail from './sample-rate-detail';
import SampleRateCreate from './sample-rate-create';
import SampleRateEdit from './sample-rate-edit';

import CategoryView from './category-view';
import CategoryCreate from './category-create';
import CategoryEdit from './category-edit';

import ChannelView from './channel-view';
import ChannelCreate from './channel-create';
import ChannelEdit from './channel-edit';

import Search from './search';

const routes = createRoutesFromElements(
  <>
    <Route path="/" element={<Layout />}>
      <Route index element={<Navigate to="/components" />} />

      <Route path="audit">
        <Route index element={<Audit />} />
      </Route>

      <Route path="components">
        <Route index element={<CategoryView />} />
        <Route path="create" element={<CategoryCreate />} />
        <Route path="edit/*" element={<CategoryEdit />} />
        <Route path="*" element={<CategoryView />} />
      </Route>

      <Route path="measurement-types">
        <Route index element={<MeasurementTypes />} />
        <Route path="create" element={<MeasurementTypeCreate />} />
        <Route path="edit/:measurementTypeId" element={<MeasurementTypeEdit />} />
        <Route path=":measurementTypeId" element={<MeasurementTypeDetail />} />
      </Route>

      <Route path="locations">
        <Route index element={<Locations />} />
        <Route path="create" element={<LocationCreate />} />
        <Route path="edit/:locationId" element={<LocationEdit />} />
        <Route path=":locationId" element={<LocationDetail />} />
      </Route>

      <Route path="units">
        <Route index element={<Units />} />
        <Route path="create" element={<UnitCreate />} />
        <Route path="edit/:unitId" element={<UnitEdit />} />
        <Route path=":unitId" element={<UnitDetail />} />
      </Route>

      <Route path="sample-rates">
        <Route index element={<SampleRates />} />
        <Route path="create" element={<SampleRateCreate />} />
        <Route path="edit/:sampleRateId" element={<SampleRateEdit />} />
        <Route path=":sampleRateId" element={<SampleRateDetail />} />
      </Route>

      <Route path="channels">
        <Route index element={<CategoryNav />} />
        <Route path="create" element={<ChannelCreate />} />
        <Route path="edit/*" element={<ChannelEdit />} />
        <Route path="*" element={<ChannelView />} />
      </Route>

      <Route path="search">
        <Route index element={<Search />} />
      </Route>
    </Route>


    <Route path="*" element={<Navigate to="/components" />} />
  </>
);

export default routes;
