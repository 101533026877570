import React from 'react';
import { useFormContext } from 'react-hook-form';

import TextField from 'components/FormFields/TextField';
import TextArea from 'components/FormFields/TextArea';
import Select2 from 'components/FormFields/Select2';

interface PropertyValueFormData {
  label: string;
  value: string;
}

interface Props {
  fieldsConfig: any;
  units: PropertyValueFormData[];
}

export default (props: Props) => {
  const { control } = useFormContext();

  return (
    <>
      <TextField
        control={control}
        config={props.fieldsConfig.rate}
      />

      <Select2
        control={control}
        config={props.fieldsConfig.unitId}
        options={props.units}
      />

      <TextArea
        control={control}
        config={props.fieldsConfig.description}
      />
    </>
  );
};
