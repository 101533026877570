import React, { useEffect } from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import {
  Intent,
} from '@blueprintjs/core';

import SampleRateFormFields from 'components/SampleRate/SampleRateFormFields';
import FormButtons from 'components/FormButtons';
import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';

import styles from './index.module.css';

const GET_SAMPLE_RATE_EDIT_PAGE_DATA = gql`
  query SampleRateEditPage($sampleRateId: Int!) {
    sampleRate: sampleRateById(sampleRateId: $sampleRateId) {
      id
      rate
      description
      unit {
        id
        identifier
      }
    }

    units {
      id
      name
      identifier
    }
  }
`;

const UPDATE_SAMPLE_RATE = gql`
  mutation SampleRateUpdate($sampleRateId: Int!, $sampleRateInput: SampleRateInput!) {
    sampleRateUpdate(sampleRateId: $sampleRateId, sampleRateInput: $sampleRateInput) {
      id
    }
  }
`;

const defaultValues = {
  rate: '',
  unitId: '',
  description: '',
};

const fieldsConfig = {
  rate: {
    name: 'rate',
    label: 'Rate',
    validation: {
      required: true,
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must only include numbers',
    },
  },
  unitId: {
    name: 'unitId',
    label: 'Unit',
    validation: {
      required: true,
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
};

interface PropertyValueFormData {
  label: string;
  value: string;
}

interface FormData {
  rate: string;
  unitId: string;
  description: string;
}

const formatUnits = (units: any[] = []): PropertyValueFormData[] => {
  return units.map((unit: any) => ({
    label: `${unit.name} (${unit.identifier})`,
    value: unit.id.toString(),
  }));
};

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const sampleRateId = Number(params.sampleRateId);
  const form = useForm<FormData>({ defaultValues, mode: 'all' });
  const watchUnitId = form.watch('unitId');

  const { loading, error, data } = useQuery(GET_SAMPLE_RATE_EDIT_PAGE_DATA, { variables: { sampleRateId } });

  const [updateSampleRate, {
    loading: updateSampleRateLoading,
  }] = useMutation(UPDATE_SAMPLE_RATE, {
    refetchQueries: [
      { query: GET_SAMPLE_RATE_EDIT_PAGE_DATA, variables: { sampleRateId } },
    ],
    onCompleted: () => {
      toaster.show({ intent: Intent.SUCCESS, message: 'Updated successfully' });
      navigate(`/sample-rates/${sampleRateId}`);
    },
    onError: ({ message }) => {
      toaster.show({
        intent: Intent.DANGER,
        message,
      });
    },
  });

  // Initialize form fields
  useEffect(() => {
    if (!data) return;

    form.setValue('rate', data.sampleRate.rate);
    form.setValue('unitId', data.sampleRate.unit.id.toString());
    form.setValue('description', data.sampleRate.description || '');
  }, [data, form]);

  if (loading) return <Spinner />;
  if (error) throw error;

  const onSubmit = async (formData: any) => {
    const variables = {
      sampleRateId: data.sampleRate.id,
      sampleRateInput: {
        rate: Number(formData.rate),
        unitId: Number(formData.unitId),
        description: formData.description || null,
      },
    };

    await updateSampleRate({ variables });
  };

  return (
    <div className={styles.container}>
      <h1 className="bp4-heading">Edit {data.sampleRate.rate} {data.sampleRate.unit.identifier} Sample Rate</h1>

      <FormProvider {...form}>
        <form className={styles.form}>
          <SampleRateFormFields
            fieldsConfig={fieldsConfig}
            units={formatUnits(data.units).sort((a: any, b: any) => a.label.localeCompare(b.label))}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            disabled={updateSampleRateLoading || watchUnitId === ''}
          />
        </form>
      </FormProvider>
    </div>
  );
};
