import React from 'react';
import {
  gql,
  useQuery,
} from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import useCheckPermission from 'hooks/use-check-permission';

import styles from './index.module.css';

const GET_SAMPLE_RATES = gql`
  query SampleRates {
    sampleRates {
      id
      rate
      description
      unit {
        id
        identifier
      }
    }
  }
`;

const columns = [
  'Sample Rate',
  'Description',
];

const formatRow = (sampleRate: any) => {
  return {
    link: `/sample-rates/${sampleRate.id}`,
    values: [
      `${sampleRate.rate} ${sampleRate.unit.identifier}`,
      sampleRate.description,
    ],
  };
};

export default () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_SAMPLE_RATES, {
    fetchPolicy: 'cache-and-network',
  });

  const [canAddSampleRates] = useCheckPermission('create_sample_rates');

  const rowRenderer = (row: any, rowIndex: number) => {
    return (
      <tr
        key={`row-${rowIndex}`}
        onClick={() => navigate(row.link)}
      >
        {row.values.map((value: string, colIndex: number) => (
          <td
            className={styles[`cell${columns[colIndex]}`]}
            key={`row-${rowIndex}-col-${colIndex}`}
          >
            {value}
          </td>
        ))}
      </tr>
    );
  };

  const renderAddSampleRateButton = () => {
    if (!canAddSampleRates) return null;

    return (
      <Button
        icon="plus"
        onClick={() => navigate('/sample-rates/create')}
      >
        Add Sample Rate
      </Button>
    );
  };


  if (loading) return <Spinner />;
  if (error) throw error;

  const rows = [...data.sampleRates] // Need to create a new array for sorting
    .sort((a: any, b: any) => a.rate - b.rate)
    .map(formatRow);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={classNames(styles.heading, 'bp4-heading')}>Sample Rates</h1>
        {renderAddSampleRateButton()}
      </header>

      <div className={styles.tableWrapper}>
        <Table
          columns={columns}
          rows={rows}
          rowRenderer={rowRenderer}
          classNames={['bp4-interactive', 'bp4-html-table-striped', styles.table]}
          sticky={{ top: 0 }}
        />
      </div>
    </div>
  );
};
