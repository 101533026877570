import React, { useEffect } from 'react';
import {
  useLocation, useNavigate,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  useForm,
  FormProvider,
} from 'react-hook-form';
import { Intent } from '@blueprintjs/core';
import _ from 'lodash';

import toaster from 'helpers/toaster';
import CategoryFormFields from 'components/Category/CategoryFormFields';
import FormButtons from 'components/FormButtons';
import Spinner from 'components/Spinner';
import ManualError from 'components/ManualError';
import { channelNameSegmentRegex } from 'config';

import styles from './index.module.css';

const GET_CATEGORY_BY_PATH = gql`
  query CategoryByPath($path: String!) {
    category: categoryByPath(path: $path) {
      id
      identifier
      path
      name
      description
      instructions
      subcategoryCount
      parentCategory {
        id
      }
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($categoryId: Int!, $categoryInput: CategoryInput!) {
    categoryUpdate(categoryId: $categoryId, categoryInput: $categoryInput) {
      id
      path
    }
  }
`;

const defaultValues = {
  name: '',
  identifier: '',
  description: '',
};

const fieldsConfig = {
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  identifier: {
    name: 'identifier',
    label: 'Identifier',
    validation: {
      required: true,
      pattern: channelNameSegmentRegex,
    },
    validationMessages: {
      pattern: 'Must only include letters and dashes. Cannot start or end with a dash.',
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
  instructions: {
    name: 'instructions',
    label: 'Instructions',
  },
};

interface FormData {
  name: string;
  identifier: string;
  description: string;
  instructions: string;
}

export default () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues, mode: 'all' });

  const path = pathname.replace('/components/edit', '');

  const {
    loading: categoryLoading,
    error: categoryError,
    data: categoryData,
  } = useQuery(GET_CATEGORY_BY_PATH, {
    variables: { path },
    fetchPolicy: 'network-only', // Prevents <CategoryPropertyEditTable /> from being provided stale data
  });

  const [updateCategory, {
    loading: updateCategoryLoading,
  }] = useMutation(UPDATE_CATEGORY, {
    onCompleted: ({ categoryUpdate }) => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Updated successfully',
      });
      navigate(`/components/${categoryUpdate.path}`);
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message,
    }),
  });

  // Initialize form state
  useEffect(() => {
    if (!categoryData) return;

    form.setValue('name', categoryData.category.name);
    form.setValue('identifier', categoryData.category.identifier);
    form.setValue('description', categoryData.category.description || '');
    form.setValue('instructions', categoryData.category.instructions || '');
  }, [categoryData, form]);

  const onSubmit = async (formData: any) => {
    await updateCategory({
      variables: {
        categoryId: categoryData.category.id,
        categoryInput: {
          parentCategoryId: categoryData.category?.parentCategory?.id ?? null,
          identifier: formData.identifier,
          name: formData.name,
          description: formData.description,
          instructions: formData.instructions,
        },
      },
    });
  };

  if (categoryLoading) return <Spinner />;
  if (categoryError) return <ManualError description={categoryError.message} />;

  const isRootCategory = categoryData.category.parentCategory === null;

  return (
    <div className={styles.container}>
      <h1 className="bp4-heading">{categoryData.category.name}</h1>

      <FormProvider {...form}>
        <form className={styles.form}>
          <div className={styles.categoryFields}>
            <CategoryFormFields
              fieldsConfig={isRootCategory ? _.omit(fieldsConfig, 'instructions') : fieldsConfig}
            />
          </div>

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            disabled={updateCategoryLoading}
          />
        </form>
      </FormProvider>
    </div>
  );
};
