import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  useForm,
  useWatch,
  FormProvider,
} from 'react-hook-form';
import {
  Callout,
  Intent,
} from '@blueprintjs/core';
import classNames from 'classnames';
import { marked } from 'marked';

import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';
import ChannelFormFields from 'components/Channel/ChannelFormFields';
import ChannelMatcher from 'components/Channel/ChannelMatcher';
import FormButtons from 'components/FormButtons';

import styles from './index.module.css';

const GET_DROPDOWN_OPTIONS = gql`
  query GetDropdownOptions {
    components: categoriesByParentCategory(parentCategoryId: null) {
      id
      identifier
      name
    }

    categories: categoryLeaves {
      id
      identifier
      name
      parentCategory {
        id
      }
    }

    measurementTypes {
      id
      identifier
      name
      description
    }

    locations {
      id
      identifier
      name
      description
    }

    units {
      id
      identifier
      name
    }

    sampleRates {
      id
      rate
    }
  }
`;

const GET_CATEGORY = gql`
  query GetCategory($categoryId: Int!) {
    category: categoryById(categoryId: $categoryId) {
      id
      identifier
      name
      hierarchy
      instructions
      component: parentCategory {
        id
        name
        identifier
      }
    }
  }
`;

const CREATE_CHANNEL = gql`
  mutation ChannelCreate($channelInput: ChannelInput!) {
    channel: channelCreate(channelInput: $channelInput) {
      id
      channelName
      category {
        path
      }
    }
  }
`;

const defaultValues = {
  shortDescription: '',
  longDescription: '',
  componentId: '',
  categoryId: '',
  measurementTypeId: '',
  locationId: '',
  unitId: '',
  sampleRateId: '',
  legacyChannelNames: [],
  fixedChannelNames: [],
  isActive: false,
};

const fieldsConfig = {
  shortDescription: {
    name: 'shortDescription',
    label: 'Short Description',
    validation: {
      required: true,
      maxLength: 150,
    },
    validationMessages: {
      maxLength: 'Must be less than 150 characters',
    },
  },
  longDescription: {
    name: 'longDescription',
    label: 'Long Description',
  },
  componentId: {
    name: 'componentId',
    label: 'Component',
    disabled: true,
  },
  categoryId: {
    name: 'categoryId',
    label: 'Subcomponent',
    disabled: true,
  },
  measurementTypeId: {
    name: 'measurementTypeId',
    label: 'Measurement Type',
    validation: {
      required: true,
    },
  },
  locationId: {
    name: 'locationId',
    label: 'Location',
    validation: {
      required: true,
    },
  },
  unitId: {
    name: 'unitId',
    label: 'Unit',
  },
  sampleRateId: {
    name: 'sampleRateId',
    label: 'Sample Rate',
  },
  legacyChannelNames: {
    name: 'legacyChannelNames',
    label: 'Legacy Channel Names',
  },
  fixedChannelNames: {
    name: 'fixedChannelNames',
    label: 'Fixed Channel Names',
  },
  isActive: {
    name: 'isActive',
    label: 'Active',
  },
};

interface PropertyValueFormData {
  label: string;
  value: string;
}

interface FormData {
  shortDescription: string;
  longDescription: string;
  componentId: string;
  categoryId: string;
  measurementTypeId: string;
  locationId: string;
  unitId: string;
  sampleRateId: string;
  legacyChannelNames: string[];
  fixedChannelNames: string[];
  isActive: boolean;
}

const formatComponents = (components: any[] = []): PropertyValueFormData[] => {
  return components
    .map(component => ({
      label: `${component.name} (${component.identifier})`,
      value: component.id.toString(),
    }));
};

const formatCategories = (categories: any[] = []): PropertyValueFormData[] => {
  return categories
    .map(category => ({
      label: `${category.name} (${category.identifier})`,
      value: category.id.toString(),
    }));
};

const formatMeasurementTypes = (measurementTypes: any[] = []): PropertyValueFormData[] => {
  return measurementTypes.map((measurementType: any) => ({
    label: `${measurementType.name} (${measurementType.identifier})`,
    value: measurementType.id.toString(),
  }));
};

const formatLocations = (locations: any[] = []): PropertyValueFormData[] => {
  return locations.map((location: any) => ({
    label: `${location.name} (${location.identifier})`,
    value: location.id.toString(),
  }));
};

const formatUnits = (units: any[] = []): PropertyValueFormData[] => {
  return units.map((unit: any) => ({
    label: `${unit.name} (${unit.identifier})`,
    value: unit.id.toString(),
  }));
};

const formatSampleRates = (sampleRates: any[] = []): PropertyValueFormData[] => {
  return sampleRates.map((sampleRate: any) => ({
    label: `${sampleRate.rate} Hz`,
    value: sampleRate.id.toString(),
  }));
};

const renderInstructions = (instructions: string) => {
  if (!instructions) return null;

  return (
    <Callout
      title="Instructions"
      icon="info-sign"
      intent="primary"
      className={styles.instructions}
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: marked(instructions) }}
      />
    </Callout>
  );
};

export default () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Sets up the form and any needed watches
  const form = useForm<FormData>({
    defaultValues: {
      ...defaultValues,
      categoryId: searchParams.get('categoryId') || '',
    },
    mode: 'all',
  });
  const { handleSubmit, control, setValue } = form;
  const componentId = useWatch({ control, name: 'componentId' });
  const categoryId = useWatch({ control, name: 'categoryId' });

  // Gets all categories and sets the `categoryId` value if one isn't already set (from `searchParams`)
  const [categories, setCategories] = useState<any[]>([]);

  const {
    loading: getDropdownOptionsLoading,
    error: getDropdownOptionsError,
    data: getDropdownOptionsData,
  } = useQuery(GET_DROPDOWN_OPTIONS);

  useEffect(() => {
    if (componentId && getDropdownOptionsData) {
      const leafCategories = [...getDropdownOptionsData.categories]
        .filter(category => category.parentCategory.id.toString() === componentId)
        .sort((a, b) => a.identifier - b.identifier);
      setCategories(leafCategories);
    }
  }, [categoryId, componentId, getDropdownOptionsData, searchParams, setCategories, setValue]);

  // When the selected category changes, fetches the full category data
  const [getCategory, {
    loading: categoryLoading,
    error: categoryError,
    data: categoryData,
  }] = useLazyQuery(GET_CATEGORY, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setValue('componentId', data.category.component.id.toString());
    },
  });

  useEffect(() => {
    if (categoryId) {
      getCategory({
        variables: { categoryId: Number(categoryId) },
      });
    }
  }, [categoryId, getCategory]);

  const [createChannel, {
    loading: createChannelLoading,
  }] = useMutation(CREATE_CHANNEL, {
    onCompleted: ({ channel }) => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Created successfully',
      });
      navigate(`/components/${channel.category.path}`);
    },
    onError: ({ message }) => {
      toaster.show({
        intent: Intent.DANGER,
        message,
      });
    },
  });

  const onSubmit = async (formData: any) => {
    await createChannel({
      variables: {
        channelInput: {
          categoryId: Number(formData.categoryId),
          measurementTypeId: Number(formData.measurementTypeId),
          locationId: Number(formData.locationId),
          unitId: /\d+/.test(formData.unitId) ? Number(formData.unitId) : null,
          sampleRateId: /\d+/.test(formData.sampleRateId) ? Number(formData.sampleRateId) : null,
          legacyChannelNames: formData.legacyChannelNames,
          fixedChannelNames: formData.fixedChannelNames,
          shortDescription: formData.shortDescription,
          longDescription: formData.longDescription,
          isActive: formData.isActive,
        },
      },
    });
  };

  if (categoryLoading || getDropdownOptionsLoading) {
    return (
      <div className={styles.container}>
        <Spinner />
      </div>
    );
  }

  // Allows GraphQL errors to be handled by the ErrorBoundary; implementing
  // ManualError here would have the same UX
  if (categoryError || getDropdownOptionsError) {
    throw categoryError ?? getDropdownOptionsError;
  }

  return (
    <div className={styles.container}>
      <FormProvider {...form}>
        <div>
          <h1 className={classNames('bp4-heading', styles.title)}>Add New {categoryData?.category.name} Channel</h1>

          <form className={styles.form}>
            {renderInstructions(categoryData?.category.instructions)}

            <ChannelFormFields
              fieldsConfig={fieldsConfig}
              components={formatComponents(getDropdownOptionsData?.components)}
              categories={formatCategories(categories)}
              measurementTypes={formatMeasurementTypes(getDropdownOptionsData?.measurementTypes)}
              locations={formatLocations(getDropdownOptionsData?.locations)}
              units={formatUnits(getDropdownOptionsData?.units)}
              sampleRates={formatSampleRates(getDropdownOptionsData?.sampleRates)}
            />

            <FormButtons
              submitHandler={handleSubmit(onSubmit)}
              disabled={createChannelLoading}
            />
          </form>
        </div>
        <div>
          <ChannelMatcher
            categoryIdentifier={categoryData?.category.identifier}
            measurementTypes={getDropdownOptionsData?.measurementTypes}
            locations={getDropdownOptionsData?.locations}
          />
        </div>
      </FormProvider>
    </div>
  );
};
