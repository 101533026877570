import React, { useState } from 'react';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Alert, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import useCheckPermission from 'hooks/use-check-permission';
import toaster from 'helpers/toaster';

import styles from './index.module.css';

const GET_MEASUREMENT_TYPE = gql`
  query MeasurementTypeById($measurementTypeId: Int!) {
    measurementType: measurementTypeById(measurementTypeId: $measurementTypeId) {
      id
      identifier
      name
      description
    }
  }
`;

const DELETE_MEASUREMENT_TYPE = gql`
  mutation DeleteMeasurementType($measurementTypeId: Int!) {
    measurementTypeRemove(measurementTypeId: $measurementTypeId)
  }
`;

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const measurementTypeId = Number(params.measurementTypeId);
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);

  const [canEditMeasurements] = useCheckPermission('update_measurement_types');
  const [canDeleteMeasurements] = useCheckPermission('delete_measurement_types');

  const { loading, error, data } = useQuery(GET_MEASUREMENT_TYPE, {
    variables: { measurementTypeId },
  });

  const [deleteMeasurementType] = useMutation(DELETE_MEASUREMENT_TYPE, {
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Deleted successfully',
      });
      navigate('/measurement-types');
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message,
    }),
  });

  const onConfirmDeleteClick = async () => {
    await deleteMeasurementType({ variables: { measurementTypeId: data.measurementType.id } });
  };

  const renderActionButtons = () => {
    return (
      <ButtonGroup>
        {canEditMeasurements && (
          <Button
            icon="edit"
            onClick={() => navigate(`/measurement-types/edit/${measurementTypeId}`)}
          >
            Edit
          </Button>
        )}
        {canDeleteMeasurements && (
          <>
            <Alert
              isOpen={deleteAlertIsOpen}
              canEscapeKeyCancel
              canOutsideClickCancel
              cancelButtonText="Cancel"
              confirmButtonText="Delete"
              intent={Intent.DANGER}
              icon="trash"
              onConfirm={onConfirmDeleteClick}
              onCancel={() => setDeleteAlertIsOpen(false)}
            >
              Are you sure you want to delete this measurement type?
              <br />
              <br />
              <strong>All channels that rely on this measurement type will also be deleted.</strong>
              <br />
              <br />
              Once deleted, it cannot be restored.
            </Alert>
            <Button
              icon="trash"
              onClick={() => setDeleteAlertIsOpen(true)}
            >
              Delete
            </Button>
          </>
        )}
      </ButtonGroup>
    );
  };

  if (loading) return <Spinner />;
  if (error) throw error;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={classNames(styles.heading, 'bp4-heading')}>{data.measurementType.name}</h1>
        {renderActionButtons()}
      </header>
      <p>Identifier: {data.measurementType.identifier}</p>
      <p>Description: {data.measurementType.description}</p>
    </div>
  );
};
