import React, { useState } from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import { Alert, Button, ButtonGroup, Intent, Tag } from '@blueprintjs/core';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import useCheckPermission from 'hooks/use-check-permission';
import toaster from 'helpers/toaster';

import styles from './index.module.css';

const GET_CHANNEL = gql`
  query ChannelByName($channelName: String!) {
    channel: channelByName(channelName: $channelName) {
      id
      shortDescription
      longDescription
      channelName
      category {
        id
        name
        path
      }
      measurementType {
        id
        identifier
        name
      }
      location {
        id
        identifier
        name
      }
      unit {
        id
        name
        identifier
      }
      sampleRate {
        id
        rate
        unit {
          id
          identifier
        }
      }
      legacyChannelNames
      fixedChannelNames
      isActive
      categoryHierarchy
    }
  }
`;

const DELETE_CHANNEL = gql`
  mutation DeleteChannel($channelId: Int!) {
    channelRemove(channelId: $channelId)
  }
`;

const columns = [
  '',
  '',
];

const formatRows = (channel: any) => {
  return [
    ['Channel Name', channel.channelName],
    ['Fixed Channel Names', channel.fixedChannelNames.join(', ')],
    ['Short Description', channel.shortDescription],
    ['Long Description', channel.longDescription],
    ['Component', `${channel.category.name} (${channel.categoryHierarchy})`],
    ['Measurement Type', channel.measurementType.name],
    ['Location', channel.location.name],
    ['Unit', channel.unit ? `${channel.unit.name} (${channel.unit.identifier})` : ''],
    ['Sample Rate', channel.sampleRate ? `${channel.sampleRate.rate} ${channel.sampleRate.unit.identifier}` : ''],
    ['Legacy Channel Names', channel.legacyChannelNames.join(', ')],
  ];
};

const rowRenderer = (values: string[], rowIndex: number) => {
  return (
    <tr key={`row-${rowIndex}`}>
      {values.map((value, colIndex) => (
        <td
          key={`row-${rowIndex}-col-${colIndex}`}
          className={styles[`cell${columns[colIndex]}`]}
        >
          {value}
        </td>
      ))}
    </tr>
  );
};

export default () => {
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const channelName = location.pathname.replace('/channels/', '');

  const [canEditChannels] = useCheckPermission('update_channels');
  const [canDeleteChannels] = useCheckPermission('delete_channels');

  const { loading, error, data } = useQuery(GET_CHANNEL, {
    variables: { channelName },
    fetchPolicy: 'network-only', // Ensures that the latest property values are fetched after update
  });

  const [deleteChannel] = useMutation(DELETE_CHANNEL, {
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Deleted successfully',
      });
      navigate(`/components/${data.channel.category.path}`);
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message,
    }),
  });

  const onConfirmDeleteClick = async () => {
    await deleteChannel({ variables: { channelId: data.channel.id } });
  };

  const renderActiveTag = (isActive: boolean) => {
    if (isActive) return <Tag intent={Intent.SUCCESS} large>Active</Tag>;

    return <Tag large>Inactive</Tag>;
  };

  const renderActionButtons = () => {
    return (
      <ButtonGroup>
        {canEditChannels && (
          <Button
            icon="edit"
            onClick={() => navigate(`/channels/edit/${channelName}`)}
          >
            Edit
          </Button>
        )}

        {canDeleteChannels && (
          <>
            <Alert
              isOpen={deleteAlertIsOpen}
              canEscapeKeyCancel
              canOutsideClickCancel
              cancelButtonText="Cancel"
              confirmButtonText="Delete"
              intent={Intent.DANGER}
              icon="trash"
              onConfirm={onConfirmDeleteClick}
              onCancel={() => setDeleteAlertIsOpen(false)}
            >
              Are you sure you want to delete this channel? Once deleted, this cannot be restored.
            </Alert>
            <Button
              icon="trash"
              onClick={() => setDeleteAlertIsOpen(true)}
            >
              Delete
            </Button>
          </>
        )}
      </ButtonGroup>
    );
  };

  if (loading) return <Spinner />;
  if (error) throw error;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={classNames('bp4-heading', styles.heading)}>{channelName}</h1>
        <div style={{ marginRight: 20 }}>
          {renderActiveTag(data.channel.isActive)}
        </div>
        {renderActionButtons()}
      </header>

      <Table
        classNames={['bp4-html-table-striped', styles.table]}
        columns={columns}
        rows={formatRows(data.channel)}
        rowRenderer={rowRenderer}
      />
    </div>
  );
};
