import React from 'react';
import { useFormContext } from 'react-hook-form';

import TagField from 'components/FormFields/TagField';
import TextField from 'components/FormFields/TextField';
import TextArea from 'components/FormFields/TextArea';
import ChannelNamePreview from 'components/Channel/ChannelNamePreview';
import Select2 from 'components/FormFields/Select2';
import Checkbox from 'components/FormFields/Checkbox';

interface PropertyValueFormData {
  label: string;
  value: string;
}

interface Props {
  fieldsConfig: any;
  components: PropertyValueFormData[];
  categories: PropertyValueFormData[];
  measurementTypes: PropertyValueFormData[];
  locations: PropertyValueFormData[];
  units: PropertyValueFormData[];
  sampleRates: PropertyValueFormData[];
}

export default (props: Props) => {
  const { control } = useFormContext();

  return (
    <>
      <Select2
        control={control}
        config={props.fieldsConfig.componentId}
        options={props.components}
      />

      <Select2
        control={control}
        config={props.fieldsConfig.categoryId}
        options={props.categories}
      />

      <Select2
        control={control}
        config={props.fieldsConfig.measurementTypeId}
        options={props.measurementTypes}
      />

      <Select2
        control={control}
        config={props.fieldsConfig.locationId}
        options={props.locations}
      />

      <Select2
        control={control}
        config={props.fieldsConfig.unitId}
        options={props.units}
      />

      <Select2
        control={control}
        config={props.fieldsConfig.sampleRateId}
        options={props.sampleRates}
      />

      <ChannelNamePreview />

      <TextField
        control={control}
        config={props.fieldsConfig.shortDescription}
      />

      <TextArea
        control={control}
        config={props.fieldsConfig.longDescription}
      />

      <TagField
        control={control}
        config={props.fieldsConfig.fixedChannelNames}
      />

      <TagField
        control={control}
        config={props.fieldsConfig.legacyChannelNames}
      />

      <Checkbox
        control={control}
        config={props.fieldsConfig.isActive}
      />
    </>
  );
};
