import React, { useState } from 'react';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Alert, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import useCheckPermission from 'hooks/use-check-permission';
import toaster from 'helpers/toaster';

import styles from './index.module.css';

const GET_SAMPLE_RATE = gql`
  query SampleRateById($sampleRateId: Int!) {
    sampleRate: sampleRateById(sampleRateId: $sampleRateId) {
      id
      rate
      description
      unit {
        id
        identifier
      }
    }
  }
`;

const DELETE_SAMPLE_RATE = gql`
  mutation DeleteSampleRate($sampleRateId: Int!) {
    sampleRateRemove(sampleRateId: $sampleRateId)
  }
`;

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const sampleRateId = Number(params.sampleRateId);
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);

  const [canEditSampleRates] = useCheckPermission('update_sample_rates');
  const [canDeleteSampleRates] = useCheckPermission('delete_sample_rates');

  const { loading, error, data } = useQuery(GET_SAMPLE_RATE, {
    variables: { sampleRateId },
  });

  const [deleteSampleRates] = useMutation(DELETE_SAMPLE_RATE, {
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Deleted successfully',
      });
      navigate('/sample-rates');
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message,
    }),
  });

  const onConfirmDeleteClick = async () => {
    await deleteSampleRates({ variables: { sampleRateId: data.sampleRate.id } });
  };

  const renderActionButtons = () => {
    return (
      <ButtonGroup>
        {canEditSampleRates && (
          <Button
            icon="edit"
            onClick={() => navigate(`/sample-rates/edit/${sampleRateId}`)}
          >
            Edit
          </Button>
        )}
        {canDeleteSampleRates && (
          <>
            <Alert
              isOpen={deleteAlertIsOpen}
              canEscapeKeyCancel
              canOutsideClickCancel
              cancelButtonText="Cancel"
              confirmButtonText="Delete"
              intent={Intent.DANGER}
              icon="trash"
              onConfirm={onConfirmDeleteClick}
              onCancel={() => setDeleteAlertIsOpen(false)}
            >
              Are you sure you want to delete this sample rate?
              <br />
              <br />
              <strong>All channels that rely on this sample rate will also be deleted.</strong>
              <br />
              <br />
              Once deleted, it cannot be restored.
            </Alert>
            <Button
              icon="trash"
              onClick={() => setDeleteAlertIsOpen(true)}
            >
              Delete
            </Button>
          </>
        )}
      </ButtonGroup>
    );
  };

  if (loading) return <Spinner />;
  if (error) throw error;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={classNames(styles.heading, 'bp4-heading')}>{data.sampleRate.rate} {data.sampleRate.unit.identifier}</h1>
        {renderActionButtons()}
      </header>
      <p>Description: {data.sampleRate.description}</p>
    </div>
  );
};
