import React from 'react';
import {
  Button,
  Intent,
} from '@blueprintjs/core';
import {
  useNavigate,
} from 'react-router-dom';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import styles from './index.module.css';

interface Props {
  submitHandler: any;
  cancelHandler?: any; // eslint-disable-line react/require-default-props
  disabled?: boolean;
}

export const FormButtons = (props: Props) => {
  const navigate = useNavigate();
  const { formState } = useFormContext();

  // HACK ALERT. This logger is needed to make the "Save" button enable on the first render after
  // the Sample Rate create/edit form becomes valid (i.e. a rate and unit are selected). If this line
  // is removed, the button doesn't enable when you enter a rate and then select a unit; you'd have to
  // select a second unit from the dropdown for it to enable. WTF.
  // eslint-disable-next-line no-console
  console.log(formState.isValid);

  return (
    <div className={styles.container}>
      <Button
        className={styles.button}
        disabled={props.disabled || !formState.isValid}
        icon="clean"
        intent={Intent.PRIMARY}
        onClick={props.submitHandler}
      >
        Save
      </Button>

      <Button
        className={classNames(styles.button, styles.cancel)}
        onClick={props.cancelHandler ?? (() => navigate(-1))} // Equivalent to hitting the back button
      >
        Cancel
      </Button>
    </div>
  );
};

FormButtons.defaultProps = {
  disabled: false,
};

export default FormButtons;
