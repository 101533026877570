import React from 'react';
import { useFormContext } from 'react-hook-form';

import TextField from 'components/FormFields/TextField';
import TextArea from 'components/FormFields/TextArea';

interface Props {
  fieldsConfig: any;
}

export default (props: Props) => {
  const { control } = useFormContext();

  return (
    <>
      <TextField
        control={control}
        config={props.fieldsConfig.name}
      />

      <TextField
        control={control}
        config={props.fieldsConfig.identifier}
      />

      <TextArea
        control={control}
        config={props.fieldsConfig.description}
      />

      {props.fieldsConfig.instructions && (
        <TextArea
          control={control}
          config={props.fieldsConfig.instructions}
        />
      )}
    </>
  );
};
