import React, { useEffect } from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import {
  Intent,
} from '@blueprintjs/core';

import LocationFormFields from 'components/Location/LocationFormFields';
import FormButtons from 'components/FormButtons';
import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';
import { channelNameSegmentRegex } from 'config';

import styles from './index.module.css';

const GET_LOCATION = gql`
  query LocationById($locationId: Int!) {
    location: locationById(locationId: $locationId) {
      id
      identifier
      name
      description
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation LocationUpdate($locationId: Int!, $locationInput: LocationInput!) {
    locationUpdate(locationId: $locationId, locationInput: $locationInput) {
      id
    }
  }
`;

const defaultValues = {
  identifier: '',
  name: '',
  description: '',
};

const fieldsConfig = {
  identifier: {
    name: 'identifier',
    label: 'Identifier',
    validation: {
      required: true,
      pattern: channelNameSegmentRegex,
    },
    validationMessages: {
      pattern: 'Must only include letters and dashes. Cannot start or end with a dash.',
    },
  },
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
};

interface FormData {
  identifier: string;
  name: string;
  description: string;
}

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const locationId = Number(params.locationId);
  const form = useForm<FormData>({ defaultValues, mode: 'all' });

  const { loading, error, data } = useQuery(GET_LOCATION, { variables: { locationId } });

  const [updateLocation, {
    loading: updateLocationLoading,
  }] = useMutation(UPDATE_LOCATION, {
    refetchQueries: [
      { query: GET_LOCATION, variables: { locationId } },
    ],
    onCompleted: () => {
      toaster.show({ intent: Intent.SUCCESS, message: 'Updated successfully' });
      navigate(`/locations/${locationId}`);
    },
    onError: ({ message }) => {
      toaster.show({
        intent: Intent.DANGER,
        message,
      });
    },
  });

  // Initialize form fields
  useEffect(() => {
    if (!data) return;

    form.setValue('identifier', data.location.identifier);
    form.setValue('name', data.location.name);
    form.setValue('description', data.location.description || '');
  }, [data, form]);

  if (loading) return <Spinner />;
  if (error) throw error;

  const onSubmit = async (formData: any) => {
    const variables = {
      locationId: data.location.id,
      locationInput: {
        identifier: formData.identifier,
        name: formData.name,
        description: formData.description || null,
      },
    };

    await updateLocation({ variables });
  };

  return (
    <div className={styles.container}>
      <h1 className="bp4-heading">Edit {data.location.name} Location</h1>

      <FormProvider {...form}>
        <form className={styles.form}>
          <LocationFormFields
            fieldsConfig={fieldsConfig}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            disabled={updateLocationLoading}
          />
        </form>
      </FormProvider>
    </div>
  );
};
