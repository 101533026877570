import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NonIdealState } from '@blueprintjs/core';
import _ from 'lodash';

import ChannelList from 'components/Channel/ChannelList';
import Spinner from 'components/Spinner';
import { determinePlurality } from 'helpers/general';
import { selectInput, selectLoading, selectResults } from 'reducers/search';

import styles from './index.module.css';

export default () => {
  const searchInput = useSelector(selectInput);
  const searchLoading = useSelector(selectLoading);
  const searchResults = useSelector(selectResults);
  const inputEmpty = useMemo(() => {
    return _.reduce(searchInput, (acc, val, key) => {
      if (acc) return acc;
      if (_.isEmpty(val)) return true;
      return false;
    }, false);
  }, [searchInput]);

  if (searchLoading) return <Spinner />;
  if (searchResults.length === 0) {
    return (
      <NonIdealState
        className={styles.empty}
        icon="search"
        title="No results"
        description={inputEmpty ? 'Try adding some things to search' : undefined}
      />
    );
  }

  return (
    <div className={styles.container}>
      <ChannelList
        columns={[
          'Status',
          `${searchResults.length} ${determinePlurality(searchResults.length, 'channel')}`,
          'Description',
          'Component',
        ]}
        rows={searchResults.map(channel => ({
          link: `/channels/${channel.channelName}`,
          values: [
            channel.isActive,
            channel.channelName,
            channel.shortDescription,
            channel.categoryHierarchy,
          ],
        }))}
      />
    </div>
  );
};
