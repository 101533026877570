import React from 'react';
import {
  Link,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import {
  Intent,
} from '@blueprintjs/core';
import _ from 'lodash';

import CategoryFormFields from 'components/Category/CategoryFormFields';
import FormButtons from 'components/FormButtons';
import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';
import { determinePlurality } from 'helpers/general';
import { channelNameSegmentRegex } from 'config';

import styles from './index.module.css';

const GET_PARENT_CATEGORY = gql`
  query ParentCategory($categoryId: Int!) {
    parentCategory: categoryById(categoryId: $categoryId) {
      id
      name
      channelCount
      categoryPropertyCount
      path
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation CreateCategory($categoryInput: CategoryInput!) {
    category: categoryCreate(categoryInput: $categoryInput) {
      id
      identifier
      name
      path
    }
  }
`;

const defaultValues = {
  name: '',
  identifier: '',
  description: '',
};

interface FieldsConfig {
  [key: string]: {
    name: string;
    label: string;
    validation?: any;
    validationMessages?: any;
  };
}

const fieldsConfig = {
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  identifier: {
    name: 'identifier',
    label: 'Identifier',
    validation: {
      required: true,
      pattern: channelNameSegmentRegex,
    },
    validationMessages: {
      pattern: 'Must only include letters and dashes. Cannot start or end with a dash.',
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
  instructions: {
    name: 'instructions',
    label: 'Instructions',
  },
} as FieldsConfig;

interface FormData {
  name: string;
  identifier: string;
  description: string;
  instructions?: string;
}

export default () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const rawParentCategoryId = searchParams.get('parentCategoryId');
  const parentCategoryId = rawParentCategoryId ? Number(rawParentCategoryId) : null;
  const isRootCategory = parentCategoryId === null;
  const form = useForm<FormData>({ defaultValues, mode: 'all' });

  const {
    loading: parentCategoryLoading,
    data: parentCategoryData,
  } = useQuery(GET_PARENT_CATEGORY, {
    variables: { categoryId: parentCategoryId },
    skip: parentCategoryId === null,
    fetchPolicy: 'cache-and-network',
  });

  const [createCategory, {
    loading: createCategoryLoading,
  }] = useMutation(CREATE_CATEGORY, {
    onCompleted: ({ category }) => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Created successfully',
      });

      navigate(`/components/${category.path}`);
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message,
    }),
  });

  const renderTitle = () => {
    if (parentCategoryId === null) return <h1 className="bp4-heading">Add New Component</h1>;
    return <h1 className="bp4-heading">Add New {parentCategoryData?.parentCategory?.name} Subcomponent</h1>;
  };

  const onSubmit = async (formData: any) => {
    await createCategory({
      variables: {
        categoryInput: {
          parentCategoryId,
          identifier: formData.identifier,
          name: formData.name,
          description: formData.description || null,
          instructions: formData.instructions || null,
        },
      },
    });
  };

  const renderChannelMoveNotice = () => {
    const { channelCount, name, path } = parentCategoryData.parentCategory;
    const noun = determinePlurality(channelCount, 'channel');

    if (channelCount === 0) return null;

    return (
      <Link to={`/components/${path}`} target="_blank">
        <p>{channelCount} {noun} will move from &quot;{name}&quot; to this category.</p>
      </Link>
    );
  };

  if (parentCategoryLoading) return <Spinner />;

  return (
    <div>
      {renderTitle()}
      <FormProvider {...form}>
        <form className={styles.form}>
          <CategoryFormFields
            fieldsConfig={isRootCategory ? _.omit(fieldsConfig, 'instructions') : fieldsConfig}
          />

          {parentCategoryData && renderChannelMoveNotice()}

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            disabled={createCategoryLoading}
          />
        </form>
      </FormProvider>
    </div>
  );
};
