import React from 'react';
import { FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { gql, useQuery } from '@apollo/client';

import { channelNameLength } from 'config';
import ValidationMessage from 'components/FormFields/ValidationMessage';
import Spinner from 'components/Spinner';

import styles from './index.module.css';

const GET_CHANNEL_NAME_PREVIEW = gql`
  query ChannelNamePreview($categoryId: Int!, $measurementTypeId: Int!, $locationId: Int!) {
    channelNamePreview(categoryId: $categoryId, measurementTypeId: $measurementTypeId, locationId: $locationId)
  }
`;

export default () => {
  const { control } = useFormContext();

  const componentId = useWatch({ control, name: 'componentId' });
  const categoryId = useWatch({ control, name: 'categoryId' });
  const measurementTypeId = useWatch({ control, name: 'measurementTypeId' });
  const locationId = useWatch({ control, name: 'locationId' });

  const missingInfo = !componentId || !categoryId || !measurementTypeId || !locationId;

  const { loading, error, data } = useQuery(GET_CHANNEL_NAME_PREVIEW, {
    variables: {
      categoryId: Number(categoryId),
      measurementTypeId: Number(measurementTypeId),
      locationId: Number(locationId),
    },
    skip: missingInfo,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <div className={styles.container}>
        <Spinner />
      </div>
    );
  }

  // Allows GraphQL errors to be handled by the ErrorBoundary; implementing ManualError here would have the same UX.
  if (error) throw error;

  const channelNamePreviewLength = data?.channelNamePreview?.length;

  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <div className={styles.previewContainer}>
      <FormGroup
        intent={channelNamePreviewLength > channelNameLength ? Intent.WARNING : Intent.NONE}
        label={<label className={styles.label}>Channel Name Preview</label>}
        style={{ width: '100%' }}
      >
        <InputGroup
          readOnly
          value={data?.channelNamePreview || 'Select a measurement and location.'}
          fill
        />
        {
          (channelNamePreviewLength > channelNameLength) && (
            <ValidationMessage message={`Channel name exceeds ${channelNameLength} characters.`} />
          )
        }
      </FormGroup>
    </div>
  );
};
