import React from 'react';
import PlatformToolbar from '@apex/react-toolkit/components/PlatformToolbar';
import { faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';

import {
  commitHash,
  env,
  packageJson,
} from 'config';
import DarkModeToggle from './DarkModeToggle';

import styles from './index.module.css';

export const reload = () => window.location.reload();

export interface Props {
  className: string;
}

export const ApexToolbar: React.FC<Props> = props => {
  return (
    <PlatformToolbar
      icon={faScrewdriverWrench}
      name="Channel Names"
      href={(env === 'development') ? '/channel-names' : '/'}
      isTesting={env === 'development'}
      settingsComponent={(
        <div className={styles.toolbarSettingsContainer}>
          <div className={styles.toolbarSettings}>
            <DarkModeToggle />
          </div>
          <p>v{packageJson.version} ({commitHash})</p>
        </div>
      )}
    />
  );
};

export default ApexToolbar;
