import React, { useEffect } from 'react';
import _ from 'lodash';
import { Icon, NonIdealState, Spinner } from '@blueprintjs/core';
import { useFormContext, useWatch } from 'react-hook-form';

import useFuzzySearch from 'hooks/use-fuzzy-search';
import ChannelList from 'components/Channel/ChannelList';
import styles from './index.module.css';

const renderSearchResults = (channels: any[]) => {
  if (channels.length === 0) return <NonIdealState icon="tick" title="No matching channels found" />;

  return (
    <ChannelList
      channelIds={_.map(channels, 'id')}
      alwaysOpenInNewTab
    />
  );
};

interface Props {
  categoryIdentifier: string;
  measurementTypes: any[];
  locations: any[];
}

export default (props: Props) => {
  const { control } = useFormContext();

  const measurementId = useWatch({ control, name: 'measurementTypeId' });
  const locationId = useWatch({ control, name: 'locationId' });

  const {
    handleSearchInputChange,
    searchLoading,
    searchError,
    searchData,
  } = useFuzzySearch();

  useEffect(() => {
    handleSearchInputChange('categoryNameOrIdentifier', props.categoryIdentifier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.categoryIdentifier]);

  useEffect(() => {
    if (measurementId === '') return;

    const measurementIdentifier = props.measurementTypes
      .find(measurementType => measurementType.id === Number(measurementId)).identifier;
    handleSearchInputChange('measurementTypeNameOrIdentifier', measurementIdentifier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.measurementTypes, measurementId]);

  useEffect(() => {
    if (locationId === '') return;

    const locationIdentifier = props.locations.find(location => location.id === Number(locationId)).identifier;
    handleSearchInputChange('locationNameOrIdentifier', locationIdentifier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.locations, locationId]);

  if (searchLoading) return <Spinner className={styles.spinner} />;
  if (searchError) return <p>Error: {searchError.message}</p>;

  return (
    <div className={styles.container}>
      <p>Existing Matches <Icon icon="help" htmlTitle="These existing channels closely or exactly match the information entered" /></p>
      <div className={styles.resultsContainer}>
        {renderSearchResults(searchData)}
      </div>
    </div>
  );
};
