import { gql, useQuery } from '@apollo/client';
import { useLocation, useSearchParams } from 'react-router-dom';

import { CATEGORY_ROOT_PATH_REGEXP } from '../constants';

const GET_ROOT_CATEGORIES = gql`
  query GetRootCategories {
    categories: categoriesByParentCategory(parentCategoryId: null) {
      id
      identifier
      name
      description
      path
      subcategoryCount
      subcategories {
        id
        name
        path
      }
    }
  }
`;

const GET_ROOT_CATEGORIES_BY_MEASUREMENT_TYPE = gql`
  query GetRootCategoriesByMeasurementType($measurementTypeId: Int!) {
    categories: measurementTypeCategories(measurementTypeId: $measurementTypeId) {
      id
      identifier
      name
      description
      path
      subcategoryCount: subcategoriesByMeasurementTypeCount(measurementTypeId: $measurementTypeId)
      subcategories: subcategoriesByMeasurementType(measurementTypeId: $measurementTypeId) {
        id
        name
        path
      }
    }
  }
`;

const GET_CATEGORY_BY_PATH = gql`
  query CategoryByPath($path: String!) {
    category: categoryByPath(path: $path) {
      id
      identifier
      name
      description
      subcategoryCount
      subcategories {
        id
        identifier
        name
        path
        subcategoryCount
        subcategories {
          id
          name
          path
        }
      }
    }
  }
`;

const GET_MEASUREMENT_TYPE_CATEGORY_BY_PATH = gql`
  query MeasurementTypeCategoryByPath($measurementTypeId: Int!, $path: String!) {
    category: measurementTypeCategoryByPath(measurementTypeId: $measurementTypeId, path: $path) {
      id
      identifier
      name
      description
      subcategoryCount: subcategoriesByMeasurementTypeCount(measurementTypeId: $measurementTypeId)
      subcategories: subcategoriesByMeasurementType(measurementTypeId: $measurementTypeId) {
        id
        name
        path
        subcategoryCount: subcategoriesByMeasurementTypeCount(measurementTypeId: $measurementTypeId)
        subcategories: subcategoriesByMeasurementType(measurementTypeId: $measurementTypeId) {
          id
          name
          path
        }
      }
    }
  }
`;

const GET_CATEGORY_SUBCATEGORIES_BY_ID = gql`
  query CategorySubcategoriesById($categoryId: Int!) {
    category: categoryById(categoryId: $categoryId) {
      id
      identifier
      subcategories {
        id
        identifier
        name
        path
        subcategoryCount
      }
    }
  }
`;

const GET_SUBCATEGORIES_BY_PATH = gql`
  query SubcategoriesByPath($path: String!) {
    category: categoryByPath(path: $path) {
      id
      identifier
      name
      subcategoryCount
      parentCategory {
        id
        subcategories {
          id
          identifier
          name
          path
          subcategoryCount
        }
      }
    }
  }
`;

const GET_MEASUREMENT_TYPE_SUBCATEGORIES_BY_PATH = gql`
  query MeasurementTypeSubcategoriesByPath($measurementTypeId: Int!, $path: String!) {
    category: measurementTypeCategoryByPath(measurementTypeId: $measurementTypeId, path: $path) {
      id
      identifier
      subcategoryCount: subcategoriesByMeasurementTypeCount(measurementTypeId: $measurementTypeId)
      parentCategory {
        id
        subcategories: subcategoriesByMeasurementType(measurementTypeId: $measurementTypeId) {
          id
          identifier
          name
          path
          subcategoryCount: subcategoriesByMeasurementTypeCount(measurementTypeId: $measurementTypeId)
        }
      }
    }
  }
`;

/*
  Use cases:
    - View root categories (i.e. no parent category).
    - View subcategories for a single category who has subcategories.
    - View sibling categories for a category that has no subcategories.
    - View subcategories of a category who is having a new subcategory created.
*/
export default (): { loading: boolean, error: any, categoryByLocation: any } => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const rawParentCategoryId = searchParams.get('parentCategoryId');
  const parentCategoryId = rawParentCategoryId ? parseInt(rawParentCategoryId, 10) : null;
  const rawMeasurementTypeId = searchParams.get('measurementTypeId');
  const measurementTypeId = rawMeasurementTypeId ? parseInt(rawMeasurementTypeId, 10) : null;
  const isMeasurementTypeView = Boolean(measurementTypeId);

  const onRootCategoryPage = /^\/components(\/edit)?(\/)?$/.test(pathname); // `/components` or `/components[/edit]`
  const onCategoryPage = /^\/components\/\S+$/.test(pathname); // `/components/path/to/subcategory`
  const onCategoryCreatePage = /^\/components\/create$/.test(pathname); // `/components/create`
  const onCategoryEditPage = /^\/components\/edit\/\S+$/.test(pathname); // `/components/edit/path/to/subcategory`

  const path = pathname.replace(CATEGORY_ROOT_PATH_REGEXP, '');

  // Homepage: Return root categories
  const {
    loading: rootCategoriesLoading,
    error: rootCategoriesError,
    data: rootCategoriesData,
  } = useQuery(GET_ROOT_CATEGORIES, {
    skip: isMeasurementTypeView || (!onRootCategoryPage && parentCategoryId !== null),
    fetchPolicy: 'cache-and-network',
  });
  const {
    loading: rootCategoriesByMeasurementTypeLoading,
    error: rootCategoriesByMeasurementTypeError,
    data: rootCategoriesByMeasurementTypeData,
  } = useQuery(GET_ROOT_CATEGORIES_BY_MEASUREMENT_TYPE, {
    variables: { measurementTypeId },
    skip: !isMeasurementTypeView,
    fetchPolicy: 'cache-and-network',
  });

  // Subcategory page: Return subcategories if the exist, otherwise return sibling categories
  const {
    loading: categoryByPathLoading,
    error: categoryByPathError,
    data: categoryByPathData,
  } = useQuery(GET_CATEGORY_BY_PATH, {
    variables: { path },
    skip: isMeasurementTypeView || (!onCategoryPage && !onCategoryEditPage),
    fetchPolicy: 'cache-and-network',
  });
  const {
    loading: measurementTypeCategoryByPathLoading,
    error: measurementTypeCategoryByPathError,
    data: measurementTypeCategoryByPathData,
  } = useQuery(GET_MEASUREMENT_TYPE_CATEGORY_BY_PATH, {
    variables: { measurementTypeId, path },
    skip: !isMeasurementTypeView || !path,
    fetchPolicy: 'cache-and-network',
  });
  const {
    loading: subcategoriesByPathLoading,
    error: subcategoriesByPathError,
    data: subcategoriesByPathData,
  } = useQuery(GET_SUBCATEGORIES_BY_PATH, {
    variables: { path },
    // Skip fetching sibiling categories for the category being viewed because the user can drill
    // down to more categories. Otherwise, show the sibiling categories for the selected category
    // because the category nav list would be empty.
    skip: isMeasurementTypeView || (!onCategoryPage && categoryByPathData?.subcategoryCount > 0),
    fetchPolicy: 'cache-and-network',
  });
  const {
    loading: measurementTypeSubcategoriesByPathLoading,
    error: measurementTypeSubcategoriesByPathError,
    data: measurementTypeSubcategoriesByPathData,
  } = useQuery(GET_MEASUREMENT_TYPE_SUBCATEGORIES_BY_PATH, {
    variables: { measurementTypeId, path },
    // Skip fetching sibiling categories for the category being viewed because the user can drill
    // down to more categories. Otherwise, show the sibiling categories for the selected category
    // because the category nav list would be empty.
    skip: !isMeasurementTypeView || !path,
    fetchPolicy: 'cache-and-network',
  });

  // Category create page: Return subcategories for the category who is having a new subcategory created.
  const {
    loading: categorySubcategoriesByIdLoading,
    error: categorySubcategoriesByIdError,
    data: categorySubcategoriesByIdData,
  } = useQuery(GET_CATEGORY_SUBCATEGORIES_BY_ID, {
    variables: { categoryId: parentCategoryId },
    skip: (!onCategoryCreatePage || parentCategoryId === null),
    fetchPolicy: 'cache-and-network',
  });

  if (onRootCategoryPage) {
    return {
      loading: rootCategoriesLoading || rootCategoriesByMeasurementTypeLoading,
      error: rootCategoriesError || rootCategoriesByMeasurementTypeError,
      categoryByLocation: { // Return a stubbed parent of root categories
        id: null, // Used as the `parentCategoryId` when creating a new category
        name: 'All components',
        categories: (rootCategoriesData ?? rootCategoriesByMeasurementTypeData)?.categories,
        subcategoryCount: 1, // So that `<CategoryView />` shows the list instead of detail
      },
    };
  }

  if (onCategoryCreatePage) {
    if (parentCategoryId === null) {
      return {
        loading: rootCategoriesLoading,
        error: rootCategoriesError,
        categoryByLocation: { // Return a stubbed parent of root categories
          id: null, // Used as the `parentCategoryId` when creating a new category
          name: 'All components',
          categories: rootCategoriesData?.categories,
          subcategoryCount: 1, // So that `<CategoryView />` shows the list instead of detail
        },
      };
    }

    return {
      loading: categorySubcategoriesByIdLoading,
      error: categorySubcategoriesByIdError,
      categoryByLocation: {
        id: categorySubcategoriesByIdData?.category?.id,
        name: categorySubcategoriesByIdData?.category?.name,
        categories: categorySubcategoriesByIdData?.category?.subcategories,
      },
    };
  }

  if (onCategoryPage || onCategoryEditPage) {
    // Return a stubbed response until the first query has completed since it'll determine what happens next.
    if (categoryByPathLoading || measurementTypeCategoryByPathLoading) {
      return {
        loading: true,
        error: null,
        categoryByLocation: {
          id: null,
          name: null,
          description: null,
          categories: [],
        },
      };
    }

    const parentData = categoryByPathData ?? measurementTypeCategoryByPathData;
    if (parentData?.category?.subcategoryCount > 0) {
      return {
        loading: categoryByPathLoading || measurementTypeCategoryByPathLoading,
        error: categoryByPathError || measurementTypeCategoryByPathError,
        categoryByLocation: {
          id: parentData?.category?.id,
          name: parentData?.category?.name,
          description: parentData?.category?.description,
          categories: parentData?.category?.subcategories,
          subcategoryCount: parentData?.category?.subcategoryCount,
        },
      };
    }

    const subcategoryData = subcategoriesByPathData ?? measurementTypeSubcategoriesByPathData;
    return {
      loading: subcategoriesByPathLoading || measurementTypeSubcategoriesByPathLoading,
      error: subcategoriesByPathError || measurementTypeSubcategoriesByPathError,
      categoryByLocation: {
        id: subcategoryData?.category?.id,
        name: subcategoryData?.category?.name,
        // Empty array fallback is for when the selected category is a root category without any subcategories.
        categories: subcategoryData?.category?.parentCategory?.subcategories ?? [],
        subcategoryCount: parentData?.category?.subcategoryCount,
        hasParentCategory: Boolean(subcategoryData?.category?.parentCategory),
      },
    };
  }

  // Fallback that should never be reached
  return {
    loading: false,
    error: null,
    categoryByLocation: {
      id: -1,
      name: 'Fallback category name',
      categories: [],
      subcategories: [],
    },
  };
};
