import React from 'react';
import { Location, useLocation } from 'react-router-dom';
import { Breadcrumbs, BreadcrumbProps, Breadcrumb, Spinner, SpinnerSize } from '@blueprintjs/core';
import {
  gql,
  useQuery,
} from '@apollo/client';

import useMeasurementTypeByParam from 'hooks/use-measurement-type-by-param';

import styles from './index.module.css';
import { basename } from '../../config';

const GET_CATEGORY_BREADCRUMBS = gql`
  query CategoryByPath($path: String!) {
    category: categoryByPath(path: $path) {
      id
      breadcrumbs {
        id
        name
        path
      }
    }
  }
`;

const buildBreadcrumbEntries = (breadcrumbs: any[], location: Location, measurementType: any): BreadcrumbProps[] => {
  const { pathname, search } = location;

  if (pathname === `${basename}/components`) return [];

  let entries = [{
    href: `${basename}/components${search}`,
    text: measurementType ? 'All Measurement Types' : 'All',
  }];

  if (measurementType) {
    entries.push({
      href: `${basename}/components${search}`,
      text: measurementType.name,
    });
  }

  entries = entries.concat(breadcrumbs.map((breadcrumb: any) => ({
    href: `${basename}/components/${breadcrumb.path}${search}`,
    text: breadcrumb.name,
  })));

  return entries;
};

export default () => {
  const location = useLocation();
  const { data: measurementTypeData } = useMeasurementTypeByParam();
  const { loading, error, data } = useQuery(GET_CATEGORY_BREADCRUMBS, {
    variables: {
      path: location.pathname,
    },
    skip: location.pathname === `${basename}/components`,
  });

  if (loading) return <Spinner size={SpinnerSize.SMALL} />;
  if (error) throw error;

  // eslint-disable-next-line max-len
  const breadcrumbs = buildBreadcrumbEntries(data?.category?.breadcrumbs, location, measurementTypeData?.measurementType);

  const breadcrumbRenderer = (breadcrumbProps: BreadcrumbProps) => (
    <Breadcrumb
      className={styles.breadcrumb}
      href={breadcrumbProps.href}
      text={breadcrumbProps.text}
    />
  );
  const currentBreadcrumbRenderer = (breadcrumbProps: BreadcrumbProps) => (
    <Breadcrumb
      className={styles.currentBreadcrumb}
      text={breadcrumbProps.text}
      current
    />
  );

  return (
    <div className={styles.breadcrumbContainer}>
      <Breadcrumbs
        breadcrumbRenderer={breadcrumbRenderer}
        className={styles.breadcrumbs}
        currentBreadcrumbRenderer={currentBreadcrumbRenderer}
        minVisibleItems={1}
        items={breadcrumbs}
      />
    </div>
  );
};
