import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormGroup,
  FormGroupProps,
  InputGroup,
  InputGroupProps,
  Intent,
} from '@blueprintjs/core';
import _ from 'lodash';

import ValidationMessage from './ValidationMessage';

interface FieldConfig {
  name: string;
  label?: string;
  validation?: any;
  validationMessages?: any;
  disabled?: boolean;
}

interface Props {
  control: any;
  config: FieldConfig;
  formGroupProps?: FormGroupProps;
  inputGroupProps?: InputGroupProps;
}

const showValidationMessage = (formState: any, name: string, validationMessages: any) => {
  const type = _.get(formState, `errors.${name}.type`);
  if (!type) return null;

  if (type === 'required') return <ValidationMessage message="Required" />;
  return <ValidationMessage message={validationMessages[type]} />;
};

export const TextField = (props: Props) => {
  return (
    <Controller
      name={props.config.name}
      control={props.control}
      rules={props.config.validation}
      render={({ field, formState }) => {
        const hasErrors = Boolean(_.get(formState.errors, props.config.name));
        return (
          <FormGroup
            intent={hasErrors ? Intent.DANGER : Intent.NONE}
            label={props.config.label}
            labelFor={`input-${props.config.name}`}
            labelInfo={props.config.validation.required ? '(required)' : ''}
            {...props.formGroupProps}
          >
            <InputGroup
              id={`input-${props.config.name}`}
              {...field}
              disabled={props.config.disabled}
              {...props.inputGroupProps}
            />
            {showValidationMessage(formState, props.config.name, props.config.validationMessages)}
          </FormGroup>
        );
      }}
    />
  );
};

TextField.defaultProps = {
  formGroupProps: {},
  inputGroupProps: {},
};

export default TextField;
