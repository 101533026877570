import React, { useEffect } from 'react';
import {
  useLocation, useNavigate,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  useForm,
  FormProvider,
} from 'react-hook-form';
import {
  Callout,
  Intent,
} from '@blueprintjs/core';
import { marked } from 'marked';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';
import ChannelFormFields from 'components/Channel/ChannelFormFields';
import ChannelMatcher from 'components/Channel/ChannelMatcher';
import FormButtons from 'components/FormButtons';

import styles from './index.module.css';

const GET_CHANNEL_EDIT_PAGE_DATA = gql`
  query ChannelEditPage($channelName: String!) {
    channel: channelByName(channelName: $channelName) {
      id
      shortDescription
      longDescription
      channelName
      component {
        id
        identifier
        name
        hierarchy
      }
      category {
        id
        identifier
        name
        hierarchy
        instructions
      }
      measurementType {
        id
        identifier
        name
      }
      location {
        id
        identifier
        name
      }
      unit {
        id
        identifier
        name
      }
      sampleRate {
        id
        rate
        unit {
          id
          identifier
        }
      }
      legacyChannelNames
      fixedChannelNames
      isActive
    }

    measurementTypes {
      id
      identifier
      name
    }

    locations {
      id
      identifier
      name
    }

    units {
      id
      identifier
      name
    }

    sampleRates {
      id
      rate
      unit {
        id
        identifier
      }
    }
  }
`;

const UPDATE_CHANNEL = gql`
  mutation ChannelUpdate($channelId: Int!, $channelInput: ChannelInput!) {
    channelUpdate(channelId: $channelId, channelInput: $channelInput) {
      id
      channelName
    }
  }
`;

const defaultValues = {
  shortDescription: '',
  longDescription: '',
  categoryId: '',
  measurementTypeId: '',
  locationId: '',
  unitId: '',
  sampleRateId: '',
  legacyChannelNames: [],
  fixedChannelNames: [],
  isActive: false,
  properties: [],
};

const fieldsConfig = {
  shortDescription: {
    name: 'shortDescription',
    label: 'Short Description',
    validation: {
      required: true,
      maxLength: 150,
    },
    validationMessages: {
      maxLength: 'Must be less than 150 characters',
    },
  },
  longDescription: {
    name: 'longDescription',
    label: 'Long Description',
  },
  componentId: {
    name: 'componentId',
    label: 'Component',
    disabled: true,
  },
  categoryId: {
    name: 'categoryId',
    label: 'Category',
    disabled: true,
  },
  measurementTypeId: {
    name: 'measurementTypeId',
    label: 'Measurement Type',
    validation: {
      required: true,
    },
  },
  locationId: {
    name: 'locationId',
    label: 'Location',
    validation: {
      required: true,
    },
  },
  unitId: {
    name: 'unitId',
    label: 'Unit',
  },
  sampleRateId: {
    name: 'sampleRateId',
    label: 'Sample Rate',
  },
  legacyChannelNames: {
    name: 'legacyChannelNames',
    label: 'Legacy Channel Names',
  },
  fixedChannelNames: {
    name: 'fixedChannelNames',
    label: 'Fixed Channel Names',
  },
  isActive: {
    name: 'isActive',
    label: 'Active',
  },
};

interface PropertyValueFormData {
  label: string;
  value: string;
}

interface PropertyFormData {
  channelId: number;
  categoryPropertyId: number;
  channelPropertyId: number;
  name: string;
  label: string;
  value: string;
  defaultOptions: PropertyValueFormData[],
  overrideOptions: PropertyValueFormData[] | null,
}

interface FormData {
  shortDescription: string;
  longDescription: string;
  componentId: string;
  categoryId: string;
  measurementTypeId: string;
  locationId: string;
  unitId: string;
  sampleRateId: string;
  legacyChannelNames: string[];
  fixedChannelNames: string[];
  isActive: boolean;
  properties: PropertyFormData[];
}

const formatComponent = (component: any): PropertyValueFormData[] => {
  return [{
    label: `${component.name} (${component.identifier})`,
    value: component.id.toString(),
  }];
};

const formatCategory = (category: any): PropertyValueFormData[] => {
  return [{
    label: `${category.name} (${category.identifier})`,
    value: category.id.toString(),
  }];
};

const formatMeasurementTypes = (measurementTypes: any[] = []): PropertyValueFormData[] => {
  return measurementTypes.map((measurementType: any) => ({
    label: `${measurementType.name} (${measurementType.identifier})`,
    value: measurementType.id.toString(),
  }));
};

const formatLocations = (locations: any[] = []): PropertyValueFormData[] => {
  return locations.map((location: any) => ({
    label: `${location.name} (${location.identifier})`,
    value: location.id.toString(),
  }));
};

const formatUnits = (units: any[] = []): PropertyValueFormData[] => {
  return units.map((unit: any) => ({
    label: `${unit.name} (${unit.identifier})`,
    value: unit.id.toString(),
  }));
};

const formatSampleRates = (sampleRates: any[] = []): PropertyValueFormData[] => {
  return sampleRates.map((sampleRate: any) => ({
    label: `${sampleRate.rate} ${sampleRate.unit.identifier}`,
    value: sampleRate.id.toString(),
  }));
};

const renderInstructions = (instructions: string) => {
  if (!instructions) return null;

  return (
    <Callout
      title="Instructions"
      icon="info-sign"
      intent="primary"
      className={styles.instructions}
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: marked(instructions) }}
      />
    </Callout>
  );
};

export default () => {
  const location = useLocation();
  const navigate = useNavigate();
  const channelName = location.pathname.replace('/channels/edit/', '');
  const form = useForm<FormData>({ defaultValues, mode: 'all' });
  const { handleSubmit, setValue } = form;

  const {
    loading: getChannelEditPageDataLoading,
    error: getChannelEditPageDataError,
    data: getChannelEditPageData,
  } = useQuery(GET_CHANNEL_EDIT_PAGE_DATA, { variables: { channelName } });

  const [updateChannel, {
    loading: updateChannelLoading,
  }] = useMutation(UPDATE_CHANNEL, {
    onCompleted: ({ channelUpdate }) => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Updated successfully',
      });

      navigate(`/channels/${channelUpdate.channelName}`);
    },
    onError: ({ message }) => {
      toaster.show({
        intent: Intent.DANGER,
        message,
      });
    },
  });

  // Initialize form fields
  useEffect(() => {
    if (!getChannelEditPageData) return;

    setValue('shortDescription', getChannelEditPageData.channel.shortDescription || '');
    setValue('longDescription', getChannelEditPageData.channel.longDescription || '');
    setValue('componentId', getChannelEditPageData.channel.component.id.toString());
    setValue('categoryId', getChannelEditPageData.channel.category.id.toString());
    setValue('measurementTypeId', getChannelEditPageData.channel.measurementType.id.toString());
    setValue('locationId', getChannelEditPageData.channel.location.id.toString());
    setValue('unitId', getChannelEditPageData.channel.unit?.id.toString() || '');
    setValue('sampleRateId', getChannelEditPageData.channel.sampleRate?.id?.toString() || '');
    setValue('legacyChannelNames', getChannelEditPageData.channel.legacyChannelNames);
    setValue('fixedChannelNames', getChannelEditPageData.channel.fixedChannelNames);
    setValue('isActive', getChannelEditPageData.channel.isActive);
  }, [getChannelEditPageData, setValue]);

  const onSubmit = async (formData: any) => {
    await updateChannel({
      variables: {
        channelId: getChannelEditPageData.channel.id,
        channelInput: {
          categoryId: Number(formData.categoryId),
          measurementTypeId: Number(formData.measurementTypeId),
          locationId: Number(formData.locationId),
          unitId: /{d}+/.test(formData.unitId) ? Number(formData.unitId) : null,
          sampleRateId: /{d}+/.test(formData.sampleRateId) ? Number(formData.sampleRateId) : null,
          legacyChannelNames: formData.legacyChannelNames,
          fixedChannelNames: formData.fixedChannelNames,
          shortDescription: formData.shortDescription,
          longDescription: formData.longDescription,
          isActive: formData.isActive,
        },
      },
    });
  };

  if (getChannelEditPageDataLoading) return <Spinner />;
  if (getChannelEditPageDataError) throw getChannelEditPageDataError;

  return (
    <div className={styles.container}>
      <FormProvider {...form}>
        <div>
          <h1 className={classNames('bp4-heading', styles.title)}>Edit Channel</h1>

          <form className={styles.form}>
            {renderInstructions(getChannelEditPageData.channel.category.instructions)}

            <ChannelFormFields
              fieldsConfig={fieldsConfig}
              components={formatComponent(getChannelEditPageData.channel.component)}
              categories={formatCategory(getChannelEditPageData.channel.category)}
              measurementTypes={formatMeasurementTypes(getChannelEditPageData.measurementTypes)}
              locations={formatLocations(getChannelEditPageData.locations)}
              units={formatUnits(getChannelEditPageData.units)}
              sampleRates={formatSampleRates(getChannelEditPageData.sampleRates)}
            />

            <FormButtons
              submitHandler={handleSubmit(onSubmit)}
              disabled={updateChannelLoading}
            />
          </form>
        </div>
        <div>
          <ChannelMatcher
            categoryIdentifier={getChannelEditPageData.channel.category.identifier}
            measurementTypes={getChannelEditPageData.measurementTypes}
            locations={getChannelEditPageData.locations}
          />
        </div>
      </FormProvider>
    </div>
  );
};
