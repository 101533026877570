import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Divider, Intent, NonIdealState, Spinner, Tag, TagInputProps } from '@blueprintjs/core';
import { ItemListPredicate, ItemListRenderer, ItemRenderer, MultiSelect } from '@blueprintjs/select';
import classNames from 'classnames';
import _ from 'lodash';

import ManualError from 'components/ManualError';
import useFuzzySearch, {
  STATIC_PARAM_MAP,
  STATIC_PARAM_MAP_INVERTED,
} from 'hooks/use-fuzzy-search';
import usePrevious from 'hooks/use-previous';
import { searchSlice } from 'reducers/search';

import styles from './index.module.css';

// Ensures param names are not empty, can contain spaces; also ensures param
// values start with alphanumeric and then can contain anything after
const PARAM_RE = /(?<paramName>[\w| ]+): *(?<paramValue>.*$)/;
interface ParamMatches {
  paramName?: string;
  paramValue?: string;
}

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    handleSearchInputChange,
    resetSearchInput,
    searchInput,
    searchLoading,
    searchError,
    searchData,
  } = useFuzzySearch();
  const SearchMultiSelect = MultiSelect.ofType<string>();
  const [params, setParams] = useState<string[]>(Object.values(STATIC_PARAM_MAP));
  const [query, setQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const prevPathname = usePrevious(location.pathname);

  const reset = useCallback(() => {
    resetSearchInput();
    setSelectedItems([]);
  }, [resetSearchInput, setSelectedItems]);

  useEffect(() => {
    dispatch(searchSlice.actions.setInput(searchInput));
  }, [dispatch, searchInput]);

  useEffect(() => {
    dispatch(searchSlice.actions.setLoading(searchLoading));
  }, [dispatch, searchLoading]);

  useEffect(() => {
    dispatch(searchSlice.actions.setResults(searchData));
  }, [dispatch, searchData]);

  useEffect(() => {
    for (let i = 0; i < selectedItems.length; i++) {
      const item = selectedItems[i];

      // Named RegEx groups because it's efficient AND cool
      const matches = item.match(PARAM_RE)?.groups;
      let { paramName, paramValue } = matches as ParamMatches;
      paramName = paramName?.trim();
      paramValue = paramValue?.trim();

      if (paramName && paramValue) {
        const paramKey = STATIC_PARAM_MAP_INVERTED[paramName];
        if (['legacyChannelNames', 'fixedChannelNames'].includes(paramKey)) {
          handleSearchInputChange(paramKey, [paramValue]);
        } else {
          handleSearchInputChange(paramKey, paramValue);
        }
      }
    }
    // Don't include `handleSearchInputChange` to avoid infinite recursion
  }, [selectedItems]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location.pathname === '/search') {
      handleSearchInputChange('limit', -1); // No limit
    } else if (prevPathname === '/search') {
      handleSearchInputChange('limit', 10);
    } else {
      reset();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSearchResults = (searchResults: any[], items: string[]): React.ReactNode => {
    const channelsGroupedByCategoryHierarchy = _.groupBy(searchResults, 'categoryHierarchy');
    return (
      <>
        {items.length > 0 && <Divider />}
        {_.map(channelsGroupedByCategoryHierarchy, (channels, categoryHierarchy) => (
          <div
            className={styles.searchGroups}
            key={categoryHierarchy}
          >
            <Link
              to={`components/${channels[0].category.path}`}
              onClick={reset}
            >
              <h3 className={styles.categoryPath}>{categoryHierarchy}</h3>
            </Link>
            <ul className={styles.searchResultsList}>
              {channels.map(channel => (
                <li
                  className={styles.searchResultItem}
                  key={channel.id}
                >
                  <Link
                    to={`/channels/${channel.channelName}`}
                    target="_blank"
                  >
                    {channel.channelName}
                  </Link>: {channel.shortDescription}
                </li>
              ))}
            </ul>
          </div>
        ))}
        {items.length > 0 && (
          <>
            <Divider />
            <Button
              fill
              intent={Intent.PRIMARY}
              large
              minimal
              onClick={() => navigate('search')}
            >
              View Full Results
            </Button>
          </>
        )}
      </>
    );
  };

  const itemListPredicate: ItemListPredicate<string> = (q, items) => {
    const selected = selectedItems.map(item => item.match(PARAM_RE)?.groups?.paramName);
    return items.filter(item => !selected.includes(item));
  };

  const itemListRenderer: ItemListRenderer<string> = props => {
    const hasResults = searchData?.length > 0;
    const hasMatches = props.items.length > 0;
    const hasParams = selectedItems.length > 0;

    return (
      <div className={classNames(styles.itemListContainer, { [styles.empty]: !hasMatches })}>
        {props.items.length > 0 && (
          <div className={styles.itemList}>
            {props.items.map(props.renderItem)}
          </div>
        )}
        {location.pathname !== '/search' && (
          <div className={classNames(styles.searchResults, { [styles.empty]: !hasMatches })}>
            {searchLoading && <Spinner />}
            {searchError && <ManualError description={searchError.message} />}
            {!searchLoading && (!hasResults || !hasParams) ? (
              <NonIdealState
                className={styles.empty}
                icon="search"
                title="No results"
                description={selectedItems.length === 0 ? 'Try adding some things to search' : undefined}
              />
            ) : renderSearchResults(searchData, props.items)}
          </div>
        )}
      </div>
    );
  };

  const itemRenderer: ItemRenderer<string> = (item, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) return null;
    return (
      <Tag
        active={modifiers.active}
        className={classNames({ [styles.activeItemTag]: modifiers.active })}
        interactive
        key={item}
        onClick={handleClick}
      >
        {STATIC_PARAM_MAP[item] ?? item}
      </Tag>
    );
  };

  const onQueryChange = async (q: string) => {
    setQuery(q);
    setParams(Object.values(STATIC_PARAM_MAP));
  };

  const onItemSelect = (item: string, event?: SyntheticEvent<HTMLElement, Event>) => {
    const isKeyboardEvent = (event as unknown as KeyboardEvent)?.key === 'Enter';
    const { paramName, paramValue } = query.match(PARAM_RE)?.groups ?? {} as ParamMatches;
    const isValidParamName = paramName && STATIC_PARAM_MAP_INVERTED[paramName];
    if (isKeyboardEvent && isValidParamName && paramValue) {
      setSelectedItems([...selectedItems, query]);
      setQuery('');
    } else {
      setQuery(`${item}: `);
    }
  };

  const tagInputProps: Partial<TagInputProps> = {
    onRemove: (tag, index) => {
      const selectedItem = selectedItems[index];
      const { paramName } = selectedItem.match(PARAM_RE)?.groups as ParamMatches;
      if (paramName) {
        const paramKey = STATIC_PARAM_MAP_INVERTED[paramName];
        handleSearchInputChange(paramKey, '');
      }
      setSelectedItems(selectedItems.filter((item, i) => i !== index));
    },
    rightElement: selectedItems.length > 0
      ? (
        <Button
          icon="cross"
          minimal
          onClick={() => {
            setSelectedItems([]);
            resetSearchInput();
          }}
        />
      )
      : undefined,
  };

  return (
    <div className={styles.searchContainer}>
      <SearchMultiSelect
        className={styles.inputField}
        fill
        itemListPredicate={itemListPredicate}
        itemListRenderer={itemListRenderer}
        itemRenderer={itemRenderer}
        items={params}
        onItemSelect={onItemSelect}
        query={query}
        onQueryChange={onQueryChange}
        placeholder="Search channels..."
        popoverProps={{ minimal: true }}
        scrollToActiveItem={false}
        selectedItems={selectedItems}
        tagInputProps={tagInputProps}
        tagRenderer={item => STATIC_PARAM_MAP[item] ?? item}
      />
    </div>
  );
};
