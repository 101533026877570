import React from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  Button,
  Intent,
  NonIdealState,
  Tag,
} from '@blueprintjs/core';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import {
  getFullyQualifiedUrl,
} from 'helpers/general';
import useCheckPermission from 'hooks/use-check-permission';

import styles from './index.module.css';

const GET_CHANNEL_LIST_DATA = gql`
  query ChannelListTable($categoryId: Int, $channelIds: [Int]) {
    table: channelListTable(categoryId: $categoryId, channelIds: $channelIds) {
      columns
      rows {
        link
        values
      }
    }
  }
`;

interface Props {
  categoryId?: number
  hasParentCategory?: boolean
  channelIds?: number[]
  columns?: any[]
  rows?: any[]
  alwaysOpenInNewTab?: boolean
}

const ChannelList = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [canAddChannels] = useCheckPermission('create_channels');

  const useSuppliedData = !_.isEmpty(props.columns);
  const { loading, error, data } = useQuery(GET_CHANNEL_LIST_DATA, {
    variables: {
      categoryId: props.categoryId,
      channelIds: props.channelIds,
    },
    fetchPolicy: 'network-only', // Always fetch the most up-to-date data
    skip: useSuppliedData,
  });

  const handleRowClick = (link: string, metaKeyPressed: boolean) => {
    if (metaKeyPressed || props.alwaysOpenInNewTab) window.open(getFullyQualifiedUrl(link));
    else navigate(link);
  };

  const rowRenderer = (row: any, rowIndex: number) => {
    const statusColIndex = 0;

    return (
      <tr key={`row-${rowIndex}`} onClick={(evt) => handleRowClick(row.link, evt.metaKey)}>
        {
          row.values.map((value: any, colIndex: number) => {
            if (colIndex === statusColIndex) {
              return (
                <td key={`row-${rowIndex}-cell-${colIndex}`}>
                  {
                    value === 'true' || value === true
                      ? <Tag intent={Intent.SUCCESS}>Active</Tag>
                      : <Tag>Inactive</Tag>
                  }
                </td>
              );
            }

            return (
              <td key={`row-${rowIndex}-cell-${colIndex}`}>{value}</td>
            );
          })
        }
      </tr>
    );
  };

  if (loading) return <Spinner />;
  if (error) throw error;

  const columns = props.columns || data.table.columns;
  const rows = props.rows || data.table.rows;

  const renderAddCategoryAction = () => {
    // Not `null` because `<NonIdealState />` expects a React component or `undefined`.
    if (!canAddChannels) return undefined;

    return (
      <Button
        icon="clean"
        intent={Intent.PRIMARY}
        onClick={() => navigate(`/components/create?parentCategoryId=${props.categoryId}`)}
      >
        Add New Subcomponent
      </Button>
    );
  };

  const renderAddChannelAction = () => {
    // Not `null` because `<NonIdealState />` expects a React component or `undefined`.
    if (!canAddChannels) return undefined;

    return (
      <Button
        icon="clean"
        intent={Intent.PRIMARY}
        onClick={() => navigate(`/channels/create?categoryId=${props.categoryId}`)}
      >
        Add New Channel
      </Button>
    );
  };

  // Used in <CategoryDetail />
  if (!props.hasParentCategory) {
    return (
      <NonIdealState
        className={styles.empty}
        icon="search"
        title="There's no subcomponents here"
        action={renderAddCategoryAction()}
      />
    );
  }

  // Used in <CategoryDetail />
  if (rows.length === 0 && props.categoryId) {
    return (
      <NonIdealState
        className={styles.empty}
        icon="search"
        title="There's no channels here"
        action={renderAddChannelAction()}
      />
    );
  }

  return (
    <Table
      classNames={['bp4-interactive', 'bp4-html-table-striped', styles.table]}
      columns={columns}
      rows={rows}
      rowRenderer={rowRenderer}
      sortable
      sticky={{ top: /^\/components\//.test(location.pathname) ? 40 : 0 }}
    />
  );
};

ChannelList.defaultProps = {
  categoryId: undefined,
  channelIds: undefined,
  rows: undefined,
  columns: undefined,
  alwaysOpenInNewTab: false,
  hasParentCategory: true,
};

export default ChannelList;
