import {
  basename,
  env,
} from 'config';

export const PropertyTypeMap: Record<string, string> = {
  NUMBER: 'Number',
  STRING: 'Text',
};

export function determinePlurality(count: number, word: string, pluralOverride?: string) {
  if (count === 1) return word;
  if (pluralOverride) return pluralOverride;
  return `${word}s`;
}

export function sortByIdentifier(records: []): any[] {
  const recordsToSort = [...records];

  return recordsToSort.sort((a: any, b: any) => {
    if (a.identifier > b.identifier) return 1;
    if (a.identifier < b.identifier) return -1;
    return 0;
  });
}

export function getFullyQualifiedUrl(path?: string) {
  if (env === 'development') return `http://localhost${basename}${path}`;
  return `${window.location.origin}${path}`;
}
