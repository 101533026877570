import { gql, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';

const GET_MEASUREMENT_TYPE_BY_ID = gql`
  query GetMeasurementTypeById($measurementTypeId: Int!) {
    measurementType: measurementTypeById(measurementTypeId: $measurementTypeId) {
      id
      name
      identifier
    }
  }
`;

export default (): { loading: boolean, error: any, data: any } => {
  const [searchParams] = useSearchParams();
  const measurementTypeId = searchParams.get('measurementTypeId');
  const { loading, error, data } = useQuery(GET_MEASUREMENT_TYPE_BY_ID, {
    variables: { measurementTypeId: Number(measurementTypeId) },
    skip: !measurementTypeId,
  });

  return { loading, error, data };
};
