import React from 'react';
import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { Colors } from '@blueprintjs/core';
import { useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import ChannelList from 'components/Channel/ChannelList';
import Spinner from 'components/Spinner';
import useMeasurementTypeByParam from 'hooks/use-measurement-type-by-param';
import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';

const GET_CHANNEL_LIST_DATA = gql`
  query ChannelListTableAndFilters($measurementTypeId: Int, $categoryId: Int, $categoryPath: String!, $channelIds: [Int]) {
    category: categoryByPath(path: $categoryPath) {
      id
      name
      description
      parentCategory {
        id
      }
    }
    table: channelListTable(measurementTypeId: $measurementTypeId, categoryId: $categoryId, categoryPath: $categoryPath, channelIds: $channelIds) {
      columns
      rows {
        link
        values
      }
    }
  }
`;

export default () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const measurementTypeIdParam = searchParams.get('measurementTypeId');
  const measurementTypeId = (measurementTypeIdParam) && Number(measurementTypeIdParam);
  const darkMode = useSelector(selectDarkMode);

  const variables: any = {
    categoryPath: location.pathname,
  };

  if (measurementTypeId) variables.measurementTypeId = measurementTypeId;

  const { loading, error, data } = useQuery(GET_CHANNEL_LIST_DATA, {
    variables,
    fetchPolicy: 'network-only', // Ensures that category property override values are fetched after update
  });

  const {
    loading: measurementTypeLoading,
    error: measurementTypeError,
    data: measurementTypeData,
  } = useMeasurementTypeByParam();

  if (loading || measurementTypeLoading) return <Spinner />;
  if (error || measurementTypeError) throw error ?? measurementTypeError;

  return (
    <div className={styles.container}>
      <h1
        className={classNames('bp4-heading', styles.heading)}
        style={{
          backgroundColor: darkMode ? Colors.DARK_GRAY2 : Colors.LIGHT_GRAY2,
        }}
      >
        {data.category.name} {measurementTypeData ? `for ${measurementTypeData.measurementType.name}` : ''}
      </h1>

      {data.category.description && (<p>{data.category.description}</p>)}

      <ChannelList
        categoryId={data.category.id}
        hasParentCategory={!!data.category.parentCategory}
        columns={data.table.columns}
        rows={data.table.rows}
      />
    </div>
  );
};
