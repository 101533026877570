import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Button, Intent, Menu, MenuDivider, MenuItem, SpinnerSize, Switch } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { gql, useMutation, useQuery } from '@apollo/client';

import { selectMeasurementTypeView, uiSlice } from 'reducers/ui';
import useCheckPermission from 'hooks/use-check-permission';
import toaster from 'helpers/toaster';
import Spinner from 'components/Spinner';
import { CATEGORY_ROOT_PATH_REGEXP } from '../../constants';

import styles from './index.module.css';

const GET_PARENT_CATEGORY = gql`
  query ParentCategoryById($categoryId: Int!) {
    category: categoryById(categoryId: $categoryId) {
      id
      name
      path
      channelCount
      parentCategory {
        id
        name
        path
      }
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($categoryId: Int!) {
    categoryRemove(categoryId: $categoryId)
  }
`;

interface Props {
  isRootCategory: boolean;
  categoryId: number | undefined;
}

export default function NavMenu(props: Props) {
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const measurementTypeView: boolean = useSelector(selectMeasurementTypeView);

  const { loading, error, data } = useQuery(GET_PARENT_CATEGORY, {
    variables: {
      categoryId: props.categoryId,
    },
    skip: !props.categoryId,
    // Handles case where the last channel is deleted which allows the delete subcomponent options to display.
    fetchPolicy: 'network-only',
  });

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Deleted successfully',
      });
      setDeleteAlertIsOpen(false);
      // TODO: Improve this implementation.
      let path = data?.category?.parentCategory?.path;
      if (path) path = `/${path}`;
      else path = '';
      navigate(`/components${path || ''}`);
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error deleting: ${message}`,
    }),
  });

  const [canAddComponents] = useCheckPermission('create_components');
  const [canAddSubcomponents] = useCheckPermission('create_subcomponents');
  const [canAddChannels] = useCheckPermission('create_channels');
  const [canEditComponents] = useCheckPermission('update_components');
  const [canEditSubcomponents] = useCheckPermission('update_subcomponents');
  const [canDeleteComponents] = useCheckPermission('delete_components');
  const [canDeleteSubcomponents] = useCheckPermission('delete_subcomponents');

  if (loading) return <Spinner size={SpinnerSize.SMALL} />;
  if (error) throw error;

  const path = pathname.replace(CATEGORY_ROOT_PATH_REGEXP, '');

  const onConfirmDeleteClick = async () => {
    await deleteCategory({ variables: { categoryId: data.category.id } });
  };

  const shouldRenderAddChannelButton = !(!canAddChannels || props.isRootCategory);

  const renderAddChannelButton = () => {
    if (!shouldRenderAddChannelButton) return null;

    const measurementTypeId = searchParams.get('measurementTypeId');

    const determineNavigateDestination = () => {
      const params = new URLSearchParams();
      if (props.categoryId) params.append('categoryId', String(props.categoryId));
      if (measurementTypeId) params.append('measurementTypeId', measurementTypeId);
      return `/channels/create?${params.toString()}`;
    };

    return (
      <MenuItem
        text="Add Channel"
        onClick={() => navigate(determineNavigateDestination())}
      />
    );
  };

  const shouldRenderEditComponentButton = !(!canEditComponents || !data);

  const renderEditComponentButton = () => {
    if (!shouldRenderEditComponentButton) return null;

    if (props.isRootCategory) {
      return (
        <MenuItem
          text={`Edit ${data?.category.name} Component`}
          onClick={() => {
            navigate(`/components/edit/${data?.category.path}`);
          }}
        />
      );
    }

    return (
      <MenuItem
        text={`Edit ${data?.category.parentCategory.name} Component`}
        onClick={() => {
          navigate(`/components/edit/${data?.category.parentCategory.path}`);
        }}
      />
    );
  };

  const shouldRenderEditCategoryButton = (canEditSubcomponents && !props.isRootCategory);

  const renderEditCategoryButton = () => {
    if (!shouldRenderEditCategoryButton) return null;

    return (
      <MenuItem
        text={`Edit ${data?.category.name} Subcomponent`}
        onClick={() => {
          navigate(`/components/edit/${path}`);
        }}
      />
    );
  };

  const shouldRenderAddComponentButton = !(!canAddComponents || pathname !== '/components');

  const renderAddComponentButton = () => {
    if (!shouldRenderAddComponentButton) return null;

    return (
      <MenuItem
        text="Add Component"
        onClick={() => navigate('/components/create')}
      />
    );
  };

  const shouldRenderAddCategoryButton = !(!canAddSubcomponents || pathname === '/components' || pathname === '/components/create');

  const renderAddCategoryButton = () => {
    if (!shouldRenderAddCategoryButton) return null;

    const category = data?.category;
    const parentCategory = data?.category?.parentCategory;

    return (
      <MenuItem
        text={`Add ${parentCategory?.name || category?.name} Subcomponent`}
        onClick={() => navigate(`/components/create?parentCategoryId=${parentCategory?.id || category?.id}`)}
      />
    );
  };

  const renderViewByMeasurementTypeSwitch = () => {
    return (
      <MenuItem
        labelClassName={styles.switchMenuItemLabel}
        textClassName={styles.switchMenuItem}
        shouldDismissPopover={false}
        text={(
          <Switch
            alignIndicator="right"
            defaultChecked={measurementTypeView}
            className={styles.switch}
            label="View by Measurement Type"
            onChange={() => {
              dispatch(uiSlice.actions.toggleMeasurementTypeView());
              navigate('/components');
            }}
          />
        )}
      />
    );
  };

  const shouldRenderDeleteComponentButton = !(!canDeleteComponents || !data || !props.isRootCategory);

  const renderDeleteComponentButton = () => {
    if (!shouldRenderDeleteComponentButton) return null;

    return (
      <MenuItem
        text={`Delete ${data?.category.name} Component`}
        onClick={() => setDeleteAlertIsOpen(true)}
        intent={Intent.DANGER}
      />
    );
  };

  // eslint-disable-next-line max-len
  const shouldRenderDeleteSubcomponentButton = !(!canDeleteSubcomponents || data?.category.channelCount > 0 || props.isRootCategory);

  const renderDeleteSubcomponentButton = () => {
    if (!shouldRenderDeleteSubcomponentButton) return null;

    return (
      <MenuItem
        text={`Delete ${data?.category.name} Subcomponent`}
        onClick={() => setDeleteAlertIsOpen(true)}
        intent={Intent.DANGER}
      />
    );
  };

  return (
    <>
      <Alert
        isOpen={deleteAlertIsOpen}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        icon="trash"
        onConfirm={onConfirmDeleteClick}
        onCancel={() => setDeleteAlertIsOpen(false)}
      >
        Are you sure? Once deleted, it cannot be restored.
      </Alert>
      <Popover2
        position="bottom"
        content={(
          <Menu>
            {renderViewByMeasurementTypeSwitch()}

            {
              // eslint-disable-next-line max-len
              (shouldRenderAddChannelButton || shouldRenderAddComponentButton || shouldRenderAddCategoryButton) && <MenuDivider />
            }
            {renderAddChannelButton()}
            {renderAddComponentButton()}
            {renderAddCategoryButton()}

            {(shouldRenderEditComponentButton || shouldRenderEditCategoryButton) && <MenuDivider />}
            {renderEditComponentButton()}
            {renderEditCategoryButton()}

            {(shouldRenderDeleteComponentButton || shouldRenderDeleteSubcomponentButton) && <MenuDivider />}
            {renderDeleteComponentButton()}
            {renderDeleteSubcomponentButton()}
          </Menu>
        )}
      >
        <Button icon="chevron-down" small />
      </Popover2>
    </>
  );
}
